import React, {Fragment} from 'react';
import {History} from 'history';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import OrdersService from '../../services/OrdersService';
import {NavLink} from 'react-router-dom';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {Toast} from 'primereact/toast';
import {
  AppMenuItem,
  MessageService,
  TwoDataTable,
  AppColumnMenuBodyTemplate,
  TwoMessage,
  ToastService,
  // UsersService,
  TwoDialog,
  // TleReferenceComponent,
  // AlarmReferenceComponent,
} from 'two-app-ui';
import {AppContext} from 'two-app-ui';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {MultiSelect} from 'primereact/multiselect';
import {
  OtherMenuOptions,
  PrintMenuOptions,
  priorities,
  StageMenuOptions,
  stages,
  types,
} from '../Order/Constants/constants';
import {formats} from '../../config/formats';
import {
  Order,
  Supplier,
  InventoryItem,
  PurchaseOrder,
  QueryParameter,
  FactoryAlarm,
  OrderType,
  CutSheetReport,
  FactoryOrderPatch,
  DropdownOption,
  Factory,
  // User,
  TleContentStageTransition,
  TimeLineEvent,
  OrderPatch,
} from 'two-core';
import values from '../../config/values';
import '../../scss/CustomTable.scss';
// import PoReferenceComponent from '../Reference/PoReferenceComponent';
// import OosReferenceComponent from '../Reference/OosReferenceComponent';
import CuttingSheets from '../CuttingSheets/CuttingSheets';
import DocumentsService from '../../services/DocumentsService';
import {Dialog} from 'primereact/dialog';
import ShipmentComponent from '../Shipment/ShipmentComponent';
import ShipmentsService from '../../services/ShipmentsService';
import OrderNoteDialog from '../Order/OrderNoteDialog';
import {
  faPlusCircle,
  faPrint,
  faArrowCircleRight,
  faStickyNote,
  faEnvelope,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import OosService from '../../services/OosService';
import {messages} from '../../config/messages';
import BomService from '../../services/BomService';
import InventoryService from '../../services/InventoryService';
import AlarmDialog from '../Alarms/AlarmDialog';
// import AlarmsService from '../../services/AlarmsService';
import FilterboxComponent from '../FilterBox/FilterboxComponent';
import {DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitchChangeParams} from 'primereact/inputswitch';
import OrderStockDialog from '../OrderStock/OrderStockDialog';
import {productLines} from '../../config/factoryConstants';
import {toInputUppercase} from '../Inventory/Constants/Utils';
import FactoriesService from '../../services/FactoriesService';
import {ProductionStageM2cAuShades} from 'two-core';
import './OrderListComponent.scss';
import TleService from '../../services/TleService';
import FactoryOrdersService from '../../services/FactoryOrdersService';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import PurchaseOrderOrderRemoveDialog from '../PurchaseOrder/PurchaseOrderOrderRemoveDialog';
import PurchaseOrderAddDialog from '../PurchaseOrders/PurchaseOrderAddDialog';
import ToOnHoldDialog from './ToOnHoldDialog';
import ProductionLabelsDialog from '../ProductionLabels/ProductionLabelsDialog';
import {OrderStageComponent} from '../Order/OrderStageComponent';
import {getFactoryOrdersMenuItems, getNewProductionStagesPatch} from '../../utils/FactoryOrderUtil';
import {getCurrentUserId} from '../../utils/UserUtil';
import {Tooltip} from 'primereact/tooltip';
import {EditEcdDialog} from '../Order/EditEcdDialog';

library.add(faPlusCircle, faPrint, faArrowCircleRight, faStickyNote, faEnvelope, faCheck);
interface Props {
  showFilterBox: boolean;
  orderTypes?: OrderType[];
  orderStages?: ProductionStageM2cAuShades[];
  printMenuOptions?: PrintMenuOptions[];
  stageMenuOptions?: StageMenuOptions[];
  otherMenuOptions?: OtherMenuOptions[];
  purchaseOrder?: PurchaseOrder;
  history: History;
}

interface State {
  loading: boolean;
  items: Order[];
  totalItems: number;
  selectedItems: Order[];
  activeFilters: {};
  filters: {
    order_code: string;
    size: number | undefined;
    reference: string;
    priority: string;
    product_line_filter: string;
    productLine: string;
    showDone: boolean;
    type: string;
    stage: string;
    customer_name: string;
    summary: string;
    ecd: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    approved_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    scheduled_for: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  poSuppliers: Supplier[];
  oosInventoryItems: InventoryItem[];
  // alarms: FactoryAlarm[];
  editAlarm: FactoryAlarm | undefined;
  printableData: CutSheetReport[] | undefined;
  productionLabelPrinterIp?: string;
  showProductionLabelDialog: boolean;
  showPurchaseOrderDialog: boolean;
  showShipmentDialog: boolean;
  showNoteDialog: boolean;
  showEditAlarmDialog: boolean;
  alarmReferencedData: string;
  prePowderOnly: boolean;
  totalActive: number;
  totalActiveFiltered: number;
  error: boolean;
  leadTime: number;
  factory?: Factory;
  showRemoveDialog: boolean;
  // users: User[];
  showHoldDialog: boolean;
  showEditEcdDialog: boolean;
  holdReason: string;
}

class OrderListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  documentsService: DocumentsService | null = null;
  shipmentService: ShipmentsService | null = null;
  oosService: OosService | null = null;
  bomService: BomService | null = null;
  inventoryService: InventoryService | null = null;
  // alarmsService: AlarmsService | null = null;
  factoriesService: FactoriesService | null = null;
  tleService: TleService | null = null;
  factoryOrdersService: FactoryOrdersService | null = null;
  purchaseOrdersService: PurchaseOrdersService | null = null;
  // usersService: UsersService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      totalItems: 0,
      loading: false,
      selectedItems: [],
      activeFilters: {},
      filters: {
        order_code: '',
        size: undefined,
        showDone: false,
        reference: '',
        priority: '',
        productLine: '',
        product_line_filter: '',
        type: '',
        stage: '',
        customer_name: '',
        summary: '',
        ecd: {
          fromDate: null,
          toDate: null,
        },
        approved_at: {
          fromDate: null,
          toDate: null,
        },
        scheduled_for: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      poSuppliers: [],
      oosInventoryItems: [],
      // alarms: [],
      editAlarm: undefined,
      printableData: undefined,
      showProductionLabelDialog: false,
      showPurchaseOrderDialog: false,
      showShipmentDialog: false,
      showNoteDialog: false,
      showEditAlarmDialog: false,
      alarmReferencedData: '',
      prePowderOnly: false,
      totalActive: 0,
      totalActiveFiltered: 0,
      error: false,
      leadTime: 0,
      showRemoveDialog: false,
      // users: [],
      showHoldDialog: false,
      showEditEcdDialog: false,
      holdReason: '',
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.setChangePoSuppliers = this.setChangePoSuppliers.bind(this);
    this.setChangeOosInventoryItems = this.setChangeOosInventoryItems.bind(this);
    this.codeBodyTemplate = this.codeBodyTemplate.bind(this);
    // this.refsBodyTemplate = this.refsBodyTemplate.bind(this);
    // this.poReferenceTemplate = this.poReferenceTemplate.bind(this);
    // this.oosReferenceTemplate = this.oosReferenceTemplate.bind(this);
    // this.alarmReferenceTemplate = this.alarmReferenceTemplate.bind(this);
    // this.lastTleBodyTemplate = this.lastTleBodyTemplate.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.approvedAtBodyTemplate = this.approvedAtBodyTemplate.bind(this);
    this.ecdBodyTemplate = this.ecdBodyTemplate.bind(this);
    this.scheduledForBodyTemplate = this.scheduledForBodyTemplate.bind(this);
    this.prioBodyTemplate = this.prioBodyTemplate.bind(this);
    this.closeShipmentDialog = this.closeShipmentDialog.bind(this);
    this.hideProductionLabels = this.hideProductionLabels.bind(this);
    this.onProductLineChange = this.onProductLineChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.hideRemoveDialog = this.hideRemoveDialog.bind(this);
    this.handleOnHoldReasonChange = this.handleOnHoldReasonChange.bind(this);
    this.closeHoldDialog = this.closeHoldDialog.bind(this);
    this.onHoldStageChange = this.onHoldStageChange.bind(this);
    this.onPrintCutSheets = this.onPrintCutSheets.bind(this);
    this.onShowPurchaseOrderDialog = this.onShowPurchaseOrderDialog.bind(this);
    this.onShowShippingLabelsDialog = this.onShowShippingLabelsDialog.bind(this);
    this.onStageChangeWithSummaryReplaced = this.onStageChangeWithSummaryReplaced.bind(this);
    this.onStageChange = this.onStageChange.bind(this);
    this.onShowAndContinueOrderStockAvailabilityCheckDialog =
      this.onShowAndContinueOrderStockAvailabilityCheckDialog.bind(this);
    this.onStageChangeWithPostFixReturnSetting = this.onStageChangeWithPostFixReturnSetting.bind(this);
    this.onShowHoldDialog = this.onShowHoldDialog.bind(this);
    this.onShowOrderStockAvailabilityCheckDialog = this.onShowOrderStockAvailabilityCheckDialog.bind(this);
    this.onShowRecordNoteDialog = this.onShowRecordNoteDialog.bind(this);
    this.onShowRemoveFromPoDialog = this.onShowRemoveFromPoDialog.bind(this);
    this.onProductionComplete = this.onProductionComplete.bind(this);
    this.onReceived = this.onReceived.bind(this);
    this.onShowEditEcdDialog = this.onShowEditEcdDialog.bind(this);
    this.onHideEditEcdDialog = this.onHideEditEcdDialog.bind(this);
  }

  async componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.documentsService = this.context.documentsService;
    this.shipmentService = this.context.shipmentsService;
    this.oosService = this.context.oosService;
    this.bomService = this.context.bomService;
    this.inventoryService = this.context.inventoryService;
    // this.alarmsService = this.context.alarmsService;
    this.factoriesService = this.context.factoriesService;
    this.tleService = this.context.tleService;
    this.factoryOrdersService = this.context.factoryOrdersService;
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    // this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;

    if (this.props.showFilterBox) {
      await this.setState({
        filters: {
          ...this.state.filters,
          productLine: localStorage.getItem('current product line') ?? 'Colourvue',
        },
      });
    }

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (
        message === messages.orderNoteCreated ||
        message === messages.purchaseOrderUpdated ||
        message === messages.ordersUpdated
      ) {
        this.loadData();
      } else if (message === messages.alarmUpdated) {
        // this.loadAlarms();
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          await localStorage.setItem('current factory', castedMessage.value as string);
          this.loadData();
        }
      }
    });

    // this.loadAlarms();
    this.loadData();
    // this.loadUsers();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  handleError(error: string): void {
    console.log(error);
    if (!this.state.error) {
      this.toastService?.showError(this.toast, error);

      this.setState({error: true});
    }
  }

  initMenuItems(): AppMenuItem[] {
    const selectedOrders = this.state.selectedItems;
    return getFactoryOrdersMenuItems(selectedOrders, {
      onPrintCutSheets: this.onPrintCutSheets,
      onShowPurchaseOrderDialog: this.onShowPurchaseOrderDialog,
      onShowShippingLabelsDialog: this.onShowShippingLabelsDialog,
      onStageChangeWithSummaryReplaced: this.onStageChangeWithSummaryReplaced,
      onStageChange: this.onStageChange,
      onReceived: this.onReceived,
      onShowAndContinueOrderStockAvailabilityCheckDialog: this.onShowAndContinueOrderStockAvailabilityCheckDialog,
      onStageChangeWithPostFixReturnSetting: this.onStageChangeWithPostFixReturnSetting,
      onProductionComplete: this.onProductionComplete,
      onShowHoldDialog: this.onShowHoldDialog,
      onShowOrderStockAvailabilityCheckDialog: this.onShowOrderStockAvailabilityCheckDialog,
      onShowRecordNoteDialog: this.onShowRecordNoteDialog,
      onShowEditEcdDialog: this.onShowEditEcdDialog,
      // onShowRemoveFromPoDialog: this.onShowRemoveFromPoDialog,
      // onSendPickUpEmail: () => {
      //  TODO: pick-up email command to be designed and implemented
      // },
    });
  }

  onShowRemoveFromPoDialog() {
    this.setState({showRemoveDialog: true});
  }

  onShowRecordNoteDialog() {
    this.setState({showNoteDialog: true});
  }

  onShowHoldDialog() {
    this.setState({showHoldDialog: true});
  }

  onShowShippingLabelsDialog() {
    this.setState({showShipmentDialog: true});
  }

  onShowPurchaseOrderDialog() {
    this.setState({showPurchaseOrderDialog: true});
  }

  hideRemoveDialog() {
    this.setState({showRemoveDialog: false});
  }

  async onProductLineChange(productLine: string) {
    await localStorage.setItem('current product line', productLine);
  }

  async loadActiveTotal(): Promise<number | undefined> {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'factory_order.product_line',
        value: this.state.filters.productLine,
      })
    );

    filters.push(
      JSON.stringify({
        field: 'factory_order.production_stage',
        value: ['Done', 'Cancelled'],
        condition: 'notIn',
      })
    );

    if (this.props.orderTypes) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: this.props.orderTypes,
          condition: 'in',
        })
      );
    }

    const params: QueryParameter = {
      offset: 0,
      page_size: 1,
      filters: filters,
      aggregate: ['factory_order'],
    };

    return this.ordersService
      ?.getOrders(params)
      .then((res: unknown) => {
        const activeTotal = (res as {total_size: number}).total_size ?? 0;
        return Promise.resolve(activeTotal);
      })
      .catch(e => {
        console.log(e);
        return Promise.resolve(0);
      });
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    //const activeTotal = (await this.loadActiveTotal()) ?? 0;
    const leadTime = (await this.loadLeadTime()) ?? 0;
    const factory = await this.loadFactory();

    if (this.props.purchaseOrder) {
      filters.push(
        JSON.stringify({
          field: 'purchase_order.id',
          value: this.props.purchaseOrder.id,
        })
      );
    }

    if (this.state.filters.order_code) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.order_code,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.reference) {
      filters.push(
        JSON.stringify({
          field: 'reference',
          value: this.state.filters.reference,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.stage?.length) {
      if (this.state.filters.productLine === 'Curtains') {
        // only the curtains have the factory chain
        filters.push(
          JSON.stringify({
            field: `factory_order.factory_chain->'${localStorage.getItem('current factory')}'->>'stage'`,
            value: this.state.filters.stage,
            condition: 'in',
          })
        );
      } else {
        // chosen other product line or all product lines
        filters.push(
          JSON.stringify({
            orConditions: [
              {
                field: 'factory_order.production_stage',
                value: this.state.filters.stage,
                condition: 'in',
              },
              {
                field: `factory_order.factory_chain->'${localStorage.getItem('current factory')}'->>'stage'`,
                value: this.state.filters.stage,
                condition: 'in',
              },
            ],
          })
        );
      }
    }

    if (this.props.showFilterBox && !this.state.filters.showDone) {
      if (this.state.filters.productLine === 'Curtains') {
        // the curtains product line was chosen -> filter finished curtains in current factory
        filters.push(
          JSON.stringify({
            field: `factory_order.factory_chain->'${localStorage.getItem('current factory')}'->>'stage'`,
            value: ['Done', 'Cancelled', 'Between Factories', 'Post Fix Return'],
            condition: 'notIn',
          })
        );
      } else {
        // the other product line was chosen -> filter finished orders in current factory
        filters.push(
          JSON.stringify({
            field: 'factory_order.production_stage',
            value: ['Done', 'Cancelled', 'Post Fix Return'],
            condition: 'notIn',
          })
        );
      }
    }

    if (this.state.filters.type && this.state.filters.type.length) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: this.state.filters.type,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.product_line_filter && this.state.filters.product_line_filter.length) {
      filters.push(
        JSON.stringify({
          field: 'factory_order.product_line',
          value: this.state.filters.product_line_filter,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.ecd.fromDate) {
      const fromDate = this.state.filters.ecd.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.ecd.toDate) {
      const toDate = this.state.filters.ecd.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.approved_at.fromDate) {
      const fromDate = this.state.filters.approved_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.approved_at.toDate) {
      const toDate = this.state.filters.approved_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.scheduled_for.fromDate) {
      const fromDate = this.state.filters.scheduled_for.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.scheduled_for',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.scheduled_for.toDate) {
      const toDate = this.state.filters.scheduled_for.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.scheduled_for',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.priority && this.state.filters.priority.length) {
      filters.push(
        JSON.stringify({
          field: 'priority',
          value: this.state.filters.priority,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.size) {
      filters.push(
        JSON.stringify({
          field: 'factory_order.size',
          value: this.state.filters.size,
        })
      );
    }

    if (this.state.filters.summary) {
      filters.push(
        JSON.stringify({
          field: 'factory_order.summary',
          value: this.state.filters.summary,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.customer_name && this.state.filters.customer_name.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'company.account_number',
          value: this.state.filters.customer_name,
          condition: 'like',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    if (this.state.filters.productLine && this.state.filters.productLine.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'factory_order.product_line',
          value: this.state.filters.productLine,
        })
      );
    }

    if (this.props.orderStages && this.props.orderStages.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'factory_order.production_stage',
          value: this.props.orderStages,
          condition: 'in',
        })
      );
    }

    if (this.props.orderTypes && this.props.orderTypes.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: this.props.orderTypes,
          condition: 'in',
        })
      );
    }

    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (sortBy.field) {
      case 'order_code':
        sortBy.field = 'id';
        break;
      case 'owner_company.account_number':
        sortBy.field = 'company.account_number';
        break;
      case 'last_activity':
        sortBy.field = 'tle.id';
        break;
    }

    const sortByStringyfied = JSON.stringify(sortBy.field ? sortBy : {field: 'approved_at', direction: 'ASC'});

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: [/*'out_of_stocks',*/ 'purchase_orders', 'factory_order', 'owner_company'],
    };

    this.ordersService
      ?.getOrders(params)
      .then((extendedData: unknown) => {
        const data = extendedData as {
          records: Order[];
          total_records: number;
          total_size: number;
        };
        const dataRecords = data.records ?? [];
        this.handleSelectedItems(dataRecords);
        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
          leadTime: leadTime,
          totalActive: 0,
          totalActiveFiltered: data.total_size ?? 0,
          factory: factory,
          selectedItems: [],
        });
      })
      .catch(error => {
        this.handleError('Records load failed');
        this.setState({loading: false});
        console.log(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | DropdownChangeParams
      | InputSwitchChangeParams
      | DateColumnFilterChangeEvent
  ) {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'productLine') {
      this.onProductLineChange(value);
    }

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  async onShowOrderStockAvailabilityCheckDialog() {
    MessageService.sendMessage(messages.orderStockCheck);
  }

  async onShowAndContinueOrderStockAvailabilityCheckDialog() {
    MessageService.sendMessage(messages.orderStockCheckAndContinue);
  }

  async onProductionComplete() {
    const selectedOrders = this.state.selectedItems;
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    if (selectedOrders && selectedOrders.length > 0) {
      this.setState({
        loading: true,
      });

      const betweenFactoriesOrders: Order[] = [];
      const doneOrders: Order[] = [];
      const postFixReturnOrders: Order[] = [];
      for (const order of selectedOrders) {
        if (order.factory_order?.factory_id !== currentFactoryId) {
          betweenFactoriesOrders.push(order);
        } else if (order.factory_order?.post_fix_return) {
          postFixReturnOrders.push(order);
        } else {
          doneOrders.push(order);
        }
      }

      if (betweenFactoriesOrders.length) {
        await this.handleStageChangeRequest(betweenFactoriesOrders, 'Between Factories');
      }
      if (doneOrders.length) {
        await this.handleStageChangeRequest(doneOrders, 'Done');
      }
      if (postFixReturnOrders.length) {
        await this.handleStageChangeRequest(postFixReturnOrders, 'Post Fix Return');
      }
    }
  }

  async onReceived() {
    const selectedOrders = this.state.selectedItems;
    if (!selectedOrders.length) {
      return;
    }
    const doneOrders: Order[] = [];
    const postFixReturnOrders: Order[] = [];
    for (const order of selectedOrders) {
      if (order.factory_order?.post_fix_return) {
        postFixReturnOrders.push(order);
      } else {
        doneOrders.push(order);
      }
    }
    if (doneOrders.length) {
      await this.handleStageChangeRequest(doneOrders, undefined, 'Done');
    }
    if (postFixReturnOrders.length) {
      await this.handleStageChangeRequest(postFixReturnOrders, undefined, 'Post Fix Return');
    }
  }

  async onHoldStageChange() {
    const {selectedItems, holdReason} = this.state;
    this.setState({
      loading: true,
      showHoldDialog: false,
    });
    this.handleStageChangeRequest(selectedItems, 'On Hold', undefined, holdReason);
  }

  async onStageChange(newStage: ProductionStageM2cAuShades, newSecondaryFactoriesStage?: ProductionStageM2cAuShades) {
    this.setState({
      loading: true,
    });
    const orders = this.state.selectedItems;
    this.handleStageChangeRequest(orders, newStage, newSecondaryFactoriesStage);
  }

  loadFactory = async () => {
    const currentFactoryId = localStorage.getItem('current factory') ?? '';

    return await this.factoriesService?.getFactory(currentFactoryId).then(factory => {
      return factory;
    });
  };

  async handleStageChangeRequest(
    orders: Order[],
    newStage?: ProductionStageM2cAuShades,
    newSecondaryFactoriesStage?: ProductionStageM2cAuShades,
    stageChangeReason?: string
  ) {
    let selectedAfterSave = this.state.selectedItems;

    const promises = orders.map(order => {
      const factoryOrderPatch: FactoryOrderPatch = getNewProductionStagesPatch(
        order.factory_order!,
        newStage,
        newSecondaryFactoriesStage,
        stageChangeReason
      );
      return this.updateFactoryOrder(factoryOrderPatch, order).then(updatedFactoryOrder => {
        if (updatedFactoryOrder) {
          this.toastService?.showSuccess(this.toast, `Stage Updated for ${updatedFactoryOrder.id} successfully.`);
          this.factoryOrdersService?.doStockUpdate(
            order.factory_order!.production_stage,
            updatedFactoryOrder.production_stage,
            updatedFactoryOrder.id!
          );
          selectedAfterSave = selectedAfterSave.filter(order => {
            return order.id !== updatedFactoryOrder.id;
          });
        }
      });
    });

    Promise.all(promises)
      .then(() => {
        this.loadData();
        this.setState({selectedItems: selectedAfterSave});
      })
      .catch(error => {
        this.handleError('Order update failed');
        this.setState({
          loading: false,
        });
        console.error(error);
      });
  }

  async updateFactoryOrder(factoryOrderPatch: FactoryOrderPatch, order: Order) {
    return this.factoryOrdersService?.updateFactoryOrder(order.id ?? '', factoryOrderPatch);
  }

  async onStageChangeWithPostFixReturnSetting() {
    const orders = [...this.state.selectedItems];
    await this.setPostFixReturn(orders, true);
  }

  async setPostFixReturn(orders: Order[], postFixReturnValue: boolean) {
    const promises = [];

    for (const order of orders) {
      if (order.factory_order) {
        const factoryOrderPatch = {
          post_fix_return: postFixReturnValue,
        };
        promises.push(this.updateFactoryOrder(factoryOrderPatch, order));
      }
    }

    return Promise.all(promises).then(() => {
      this.onShowAndContinueOrderStockAvailabilityCheckDialog();
    });
  }

  async onStageChangeWithSummaryReplaced(replaceSummary: string) {
    const orders = [...this.state.selectedItems];
    this.replaceSummaryOfOrders(orders, replaceSummary).then(() => this.onStageChange('Ready'));
  }

  async replaceSummaryOfOrders(orders: Order[], replaceSummary: string) {
    const date = DateTime.fromJSDate(new Date()).toFormat(formats.date);
    const dateSummary = `&#10004; (${date})`;

    const updatedOrders = orders.map(o => {
      const oldSummary = o.summary ?? '';
      const newSummary = `${replaceSummary} ${dateSummary}`;
      o.summary = oldSummary.replace(replaceSummary, newSummary);
      return o;
    });

    await this.saveOrders(updatedOrders);
  }

  async saveOrders(orders: Order[]) {
    if (orders && orders.length > 0) {
      Promise.all(
        orders.map((order: Order) => {
          return this.ordersService
            ?.updateOrder(order.id ?? '', order as OrderPatch)
            .then(() => {
              this.toastService?.showSuccess(this.toast, `Order ${order.id} updated successfully.`);
            })
            .catch(error => {
              this.handleError(`${order.id} update failed`);
              console.error('error: ' + error);
            });
        })
      );
    }
  }

  // async loadAlarms() {
  //   const filters: string[] = [];
  //
  //   const sortBy = JSON.stringify({
  //     field: 'created_at',
  //     direction: 'ASC',
  //   });
  //
  //   const params: QueryParameter = {
  //     filters: filters,
  //     orderBys: [sortBy],
  //   };
  //
  //   this.alarmsService
  //     ?.getAlarms(params)
  //     .then(data => {
  //       const alarms = data.records as FactoryAlarm[];
  //
  //       this.setState({
  //         alarms: alarms,
  //       });
  //     })
  //     .catch(e => {
  //       console.log(e);
  //       this.handleError('Alarm records load failed');
  //     });
  // }

  // loadUsers() {
  //   const params: QueryParameter = {
  //     filters: [],
  //     aggregate: false,
  //   };
  //
  //   this.usersService
  //     ?.getUsers(params)
  //     .then(data => {
  //       const users: User[] = (data?.records as User[]) ?? [];
  //
  //       this.setState({
  //         users: users,
  //       });
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // }

  async loadLeadTime() {
    let leadTime = 0;
    const orderTypes = this.props.orderTypes ?? [];

    if (orderTypes.length === 1) {
      const orderType = (this.props.orderTypes ?? [])[0];
      if (orderType === 'Component' || orderType === 'Repair' || orderType === 'Standard') {
        const currentFactoryId = localStorage.getItem('current factory') ?? '';

        return this.factoriesService
          ?.getFactory(currentFactoryId)
          .then(data => {
            const factory = data;
            const productionLine = this.state.filters.productLine;
            switch (productionLine) {
              case 'Colourvue':
                switch (orderType) {
                  case 'Standard':
                    leadTime = factory.settings.product_lines.colourvue?.lead_time.standard ?? 0;
                    break;
                  case 'Component':
                    leadTime = factory.settings.product_lines.colourvue?.lead_time.component ?? 0;
                    break;
                  case 'Repair':
                    leadTime = factory.settings.product_lines.colourvue?.lead_time.repair ?? 0;
                    break;
                }
                break;
              case 'Shadesol':
                switch (orderType) {
                  case 'Standard':
                    leadTime = factory.settings.product_lines.shadesol?.lead_time.standard ?? 0;
                    break;
                  case 'Component':
                    leadTime = factory.settings.product_lines.shadesol?.lead_time.component ?? 0;
                    break;
                  case 'Repair':
                    leadTime = factory.settings.product_lines.shadesol?.lead_time.repair ?? 0;
                    break;
                }
                break;
              case 'Curtains':
                switch (orderType) {
                  case 'Standard':
                    leadTime = factory.settings.product_lines.curtains?.lead_time.standard ?? 0;
                    break;
                  case 'Component':
                    leadTime = factory.settings.product_lines.curtains?.lead_time.component ?? 0;
                    break;
                  case 'Repair':
                    leadTime = factory.settings.product_lines.curtains?.lead_time.repair ?? 0;
                    break;
                }
                break;
            }
            return Promise.resolve(leadTime);
          })
          .catch(e => {
            return Promise.reject(e);
          });
      } else {
        return Promise.resolve(leadTime);
      }
    } else {
      return Promise.resolve(leadTime);
    }
  }

  async showAlarm(alarm: FactoryAlarm, order: Order) {
    const referenceData = `${order.id} ${order.reference}`;
    await this.setState({
      editAlarm: alarm,
      showEditAlarmDialog: true,
      alarmReferencedData: referenceData,
    });

    MessageService.sendMessage(messages.alarmEdit);
  }

  handleSelectedItems(allItems: Order[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Order[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Order[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: Order) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  setChangePoSuppliers(suppliers: Supplier[]) {
    this.setState({poSuppliers: suppliers});
  }

  setChangeOosInventoryItems(inventoryItems: InventoryItem[]) {
    this.setState({oosInventoryItems: inventoryItems});
  }

  async onPrintCutSheets(prePowderOnly: boolean) {
    const selectedOrders: string[] = [];
    let atLeastOneStandard = false;
    for (const order of this.state.selectedItems) {
      if (order.id) {
        selectedOrders.push(order.id);
        if (order.type === 'Standard') {
          atLeastOneStandard = true;
        }
      }
    }

    const printableData = await this.documentsService?.getCuttingSheets(selectedOrders);
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    const printerIP = await this.loadProductionPrinterIp(currentFactoryId);

    this.setState(
      {
        printableData: printableData,
        productionLabelPrinterIp: printerIP,
        showProductionLabelDialog:
          atLeastOneStandard && localStorage.getItem('current factory') === '86c7576a-2d38-4092-b1c3-5ef101f415bd',
        prePowderOnly: prePowderOnly,
      },
      () => MessageService.sendMessage('print-cutting-sheets')
    );
  }

  onHideEditEcdDialog() {
    this.setState({showEditEcdDialog: false});
    this.loadData();
  }

  onShowEditEcdDialog() {
    this.setState({showEditEcdDialog: true});
  }

  async loadProductionPrinterIp(currentFactoryId: string) {
    return this.factoriesService
      ?.getFactory(currentFactoryId)
      .then(data => {
        return data.settings?.printers?.production_label_printer_ip ?? '';
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Production Label Printer IP not found');
        return undefined;
      });
  }

  closeShipmentDialog() {
    this.setState({showShipmentDialog: false});
  }

  hideProductionLabels() {
    this.setState({showProductionLabelDialog: false});
  }

  closeHoldDialog() {
    this.setState({showHoldDialog: false});
  }

  handleOnHoldReasonChange(reason: string) {
    this.setState({holdReason: reason});
  }

  codeBodyTemplate(rowData: Order) {
    return (
      <React.Fragment>
        <AppColumnMenuBodyTemplate
          rowItemIdentifier={rowData?.id?.toString() ?? ''}
          isDynamicMenuItems={true}
          initMenuItems={() => this.initMenuItems()}
          selectedItems={this.state.selectedItems}
          handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <NavLink to={'/order/' + rowData.id}>{rowData.id}</NavLink>
        </AppColumnMenuBodyTemplate>
      </React.Fragment>
    );
  }

  prioBodyTemplate(rowData: Order) {
    let priorityText = '';
    switch (rowData.priority) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return <span className={`stage-badge prio-${rowData.priority}`}>{priorityText}</span>;
  }

  // lastTleBodyTemplate(rowData: Order) {
  //   const lastTle = rowData.last_tle;
  //   if (lastTle) {
  //     const user = this.state.users.find(u => u.id === lastTle.recorded_by);
  //     return <TleReferenceComponent identifier={rowData.id ?? ''} value={lastTle} user={user} />;
  //   } else {
  //     return <></>;
  //   }
  // }

  // refsBodyTemplate(rowData: Order) {
  //   return (
  //     <div className="p-d-flex p-flex-row p-flex-wrap">
  //       {this.poReferenceTemplate(rowData)}
  //       {this.oosReferenceTemplate(rowData)}
  //       {this.alarmReferenceTemplate(rowData)}
  //     </div>
  //   );
  // }

  // poReferenceTemplate(rowData: Order) {
  //   return (
  //     <PoReferenceComponent
  //       key={'PO-' + rowData.id}
  //       identifier={rowData.id ?? ''}
  //       purchaseOrders={rowData.purchase_orders ?? []}
  //       suppliers={this.state.poSuppliers}
  //       handleChangePoSupplier={suppliers => this.setChangePoSuppliers(suppliers)}
  //       handlePoReferenceClick={po => {
  //         this.props.history.push(`/purchase-order/${po.id}`);
  //       }}
  //     />
  //   );
  // }

  // oosReferenceTemplate(rowData: Order) {
  //   return (
  //     <OosReferenceComponent
  //       key={'OOS-' + rowData.id}
  //       identifier={rowData.id ?? ''}
  //       oosRecords={rowData.out_of_stocks ?? []}
  //       oosInventoryItems={this.state.oosInventoryItems}
  //       handleChangeOosInventoryItems={inventoryItems => this.setChangeOosInventoryItems(inventoryItems)}
  //       handleOosReferenceClick={oos => {
  //         this.props.history.push(`/oos-item/${oos.id}`);
  //       }}
  //     />
  //   );
  // }

  // alarmReferenceTemplate(rowData: Order) {
  //   const alarms = this.state.alarms?.filter(a => a.reference_id === rowData.id) ?? [];
  //   return (
  //     <AlarmReferenceComponent
  //       identifier={rowData.id ?? ''}
  //       factoryAlarms={alarms}
  //       handleAlarmReferenceClick={alarm => this.showAlarm(alarm, rowData)}
  //     />
  //   );
  // }

  stageBodyTemplate(order: Order) {
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    return <OrderStageComponent order={order} currentFactoryId={currentFactoryId} />;
  }

  approvedAtBodyTemplate(rowData: Order) {
    const formated_approved_on = rowData.approved_at
      ? DateTime.fromISO(rowData.approved_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_approved_on}</span>;
  }

  ecdBodyTemplate(rowData: Order) {
    const formated_ecd = rowData.factory_order?.ecd
      ? DateTime.fromISO(rowData.factory_order?.ecd.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_ecd}</span>;
  }

  scheduledForBodyTemplate(rowData: Order) {
    const formated_scheduled_for = rowData.factory_order?.scheduled_for
      ? DateTime.fromISO(rowData.factory_order?.scheduled_for.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_scheduled_for}</span>;
  }

  handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | InputSwitchChangeParams
  ) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  availableStageOptions() {
    const allStages = this.props.orderStages
      ? this.props.orderStages.map(o => {
          const l = o === 'Ready' ? 'Printed' : o;
          const option: DropdownOption = {label: l, value: o};
          return option;
        })
      : stages;

    if (this.props.showFilterBox) {
      if (this.state.filters.showDone) {
        return allStages;
      } else {
        return allStages.filter(option => {
          return option.value !== 'Done' && option.value !== 'Cancelled';
        });
      }
    } else {
      return allStages;
    }
  }

  customerBody(order: Order) {
    const owner = order.owner_company;
    const id = `customer_${order.id}_${owner?.account_number}`.replaceAll(' ', '_');
    return (
      <div>
        <span id={id}>{owner?.account_number}</span>
        <Tooltip target={`#${id}`} position="right" showDelay={500} mouseTrack mouseTrackLeft={15}>
          <div className="p-d-flex p-flex-column">
            <span>{owner?.account_number}</span>
            <span>{owner?.name}</span>
            <span>{owner?.trading_as}</span>
          </div>
        </Tooltip>
      </div>
    );
  }

  referenceBody(order: Order) {
    const id = `reference_${order.id}`;
    return (
      <div>
        <span id={id}>{order.reference}</span>
        <Tooltip target={`#${id}`} position="right" showDelay={500} mouseTrack mouseTrackLeft={15}>
          <span>{order.reference}</span>
        </Tooltip>
      </div>
    );
  }

  summaryBody(order: Order) {
    const id = `summary_${order.id}`;
    return (
      <div>
        <span id={id}>{order.factory_order?.summary}</span>
        <Tooltip target={`#${id}`} position="right" showDelay={500} mouseTrack mouseTrackLeft={15}>
          <span>{order.factory_order?.summary}</span>
        </Tooltip>
      </div>
    );
  }

  render() {
    const codeFilter = (
      <InputText
        name="order_code"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );
    const referenceFilter = (
      <InputText
        name="reference"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const selectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span className={`p-mr-1 stage-badge stage-badge-filter stage-${value.toLowerCase().replaceAll(' ', '-')}`}>
            {value}
          </span>
        );
      }

      return <></>;
    };

    const itemTemplate = (option: DropdownOption) => {
      return (
        <span className={`stage-badge stage-${(option.value as string).toLowerCase().replaceAll(' ', '-')}`}>
          {option.label}
        </span>
      );
    };

    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={selectedItemTemplate}
        itemTemplate={itemTemplate}
        value={this.state.filters.stage}
        options={this.availableStageOptions()}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const typeFilter = (
      <MultiSelect
        value={this.state.filters.type}
        options={this.props.orderTypes ? this.props.orderTypes : types}
        name="type"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const productLineFilter = (
      <MultiSelect
        value={this.state.filters.product_line_filter}
        options={productLines}
        name="product_line_filter"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const priorityFilter = (
      <MultiSelect
        value={this.state.filters.priority}
        options={priorities}
        name="priority"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const approvedAtFilter = (
      <DateColumnFilter
        name="approved_at"
        value={this.state.filters.approved_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const scheduledForFilter = (
      <DateColumnFilter
        name="scheduled_for"
        value={this.state.filters.scheduled_for}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const ecdFilter = (
      <DateColumnFilter name="ecd" value={this.state.filters.ecd} onChange={e => this.onFilterChange(e)} />
    );

    const sizeFilter = (
      <InputText
        name="size"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const summaryFilter = (
      <InputText
        name="summary"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const customerFilter = (
      <InputText
        name="customer_name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );

    return (
      <div
        id="order_list_page_container"
        className={`page-container ${this.props.orderTypes?.[0]?.toLocaleLowerCase()}`}
      >
        {this.props.showFilterBox && (
          <div id="filter-box" className={'p-d-flex'}>
            <FilterboxComponent
              leadTime={this.state.leadTime}
              totalActive={this.state.totalActive}
              totalActiveFiltered={this.state.totalActiveFiltered}
              selectedProductLine={this.state.filters.productLine}
              showDoneOrders={this.state.filters.showDone}
              handleFilterChange={this.onFilterChange}
            />
          </div>
        )}

        <TwoDataTable
          pageSizeIdentifier={'order_list_page_container'}
          sizeIdentifiers={this.props.showFilterBox ? ['filter-box'] : []}
          selectionMode="multiple"
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as Order[])}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
          initMenuItems={this.initMenuItems}
        >
          <Column
            header="Order Code"
            field="order_code"
            body={this.codeBodyTemplate}
            filter
            filterElement={codeFilter}
            sortable
            className="col-l"
            showFilterMenu={false}
          />
          <Column
            header="Reference"
            field="reference"
            body={this.referenceBody}
            filter
            filterElement={referenceFilter}
            sortable
            className="col-min-xl"
            showFilterMenu={false}
          />
          <Column
            header="Customer"
            field="owner_company.account_number"
            body={this.customerBody}
            filter
            filterElement={customerFilter}
            sortable
            className="col-m"
            showFilterMenu={false}
          />
          {!this.props.showFilterBox && (
            <Column
              header="Prod Line"
              field="factory_order.product_line"
              filter
              filterElement={productLineFilter}
              sortable
              className="col-m"
              showFilterMenu={false}
            />
          )}
          {(!this.props.orderTypes || this.props.orderTypes.length > 1) && (
            <Column
              header="Type"
              field="type"
              filter
              filterElement={typeFilter}
              sortable
              className="col-max-m"
              showFilterMenu={false}
            />
          )}
          <Column
            header="Stage"
            field="factory_order.production_stage"
            body={this.stageBodyTemplate}
            filter
            filterElement={stageFilter}
            sortable
            className="col-max-xl"
            showFilterMenu={false}
          />
          {/*progress*/}
          <Column
            header="Date"
            field="approved_at"
            body={this.approvedAtBodyTemplate}
            filter
            filterElement={approvedAtFilter}
            sortable
            className="col-max-m"
            showFilterMenu={false}
          />
          <Column
            header="ECD"
            field="factory_order.ecd"
            body={this.ecdBodyTemplate}
            filter
            filterElement={ecdFilter}
            sortable
            className="col-max-m"
            showFilterMenu={false}
          />
          {/*{(!this.props.orderTypes || this.props.orderTypes.length === 1) && (*/}
          {/*  <Column*/}
          {/*    header="Release"*/}
          {/*    field="factory_order.scheduled_for"*/}
          {/*    body={this.scheduledForBodyTemplate}*/}
          {/*    filter*/}
          {/*    filterElement={scheduledForFilter}*/}
          {/*    sortable*/}
          {/*    showFilterMenu={false}*/}
          {/*  />*/}
          {/*)}*/}
          <Column
            header="Prio"
            field="priority"
            body={this.prioBodyTemplate}
            filter
            filterElement={priorityFilter}
            sortable
            className="col-max-m"
            showFilterMenu={false}
          />
          <Column
            header="Size"
            field="factory_order.size"
            filter
            filterElement={sizeFilter}
            sortable
            className="col-max-s"
            showFilterMenu={false}
          />
          <Column
            header="Summary"
            field="factory_order.summary"
            body={this.summaryBody}
            filter
            filterElement={summaryFilter}
            sortable
            className="col-min-xxl"
            showFilterMenu={false}
          />
          {/*<Column*/}
          {/*  header="Last Action"*/}
          {/*  field="last_activity"*/}
          {/*  body={this.lastTleBodyTemplate}*/}
          {/*  sortable*/}
          {/*  showFilterMenu={false}*/}
          {/*/>*/}
          {/*<Column header="Refs" body={this.refsBodyTemplate} style={{width: '300px'}} showFilterMenu={false} />*/}
        </TwoDataTable>
        <Toast ref={this.toast} />
        <TwoDialog
          className="purchaser-order-date-dialog"
          headerTitle={'On Hold'}
          showDialog={this.state.showHoldDialog}
          width={60}
          onHide={this.closeHoldDialog}
          onSave={this.onHoldStageChange}
          loading={this.state.loading}
        >
          <ToOnHoldDialog reason={this.state.holdReason} handleOnHoldReasonChange={this.handleOnHoldReasonChange} />
        </TwoDialog>
        <OrderNoteDialog
          showDialog={this.state.showNoteDialog}
          onHide={() => this.setState({showNoteDialog: false})}
          selectedOrders={this.state.selectedItems}
        />
        <PurchaseOrderAddDialog
          toast={this.toast}
          showPurchaseOrderDialog={this.state.showPurchaseOrderDialog}
          closeDialog={() => {
            this.setState({showPurchaseOrderDialog: false});
          }}
          order={this.state.selectedItems ? this.state.selectedItems[0] : undefined}
        />
        <AlarmDialog
          showDialog={this.state.showEditAlarmDialog}
          toast={this.toast}
          selectedAlarm={this.state.editAlarm}
          selectedAlarmReferencedData={this.state.alarmReferencedData}
          onHide={() => {
            this.setState({
              showEditAlarmDialog: false,
              editAlarm: undefined,
              alarmReferencedData: '',
            });
          }}
        />
        <OrderStockDialog toast={this.toast} orders={this.state.selectedItems} />
        {this.state.selectedItems.length > 0 && (
          <>
            <CuttingSheets
              type={this.state.selectedItems[0] ? this.state.selectedItems[0].factory_order?.product_line : ''}
              data={this.state.printableData}
              prePowderOnly={this.state.prePowderOnly}
            />
            <ProductionLabelsDialog
              show={this.state.showProductionLabelDialog}
              printerIp={this.state.productionLabelPrinterIp}
              data={this.state.printableData}
              onHide={this.hideProductionLabels}
            />
            <Dialog
              header={
                this.state.items.length > 1
                  ? 'Shipping Labels for Multiple Orders'
                  : `Shipping Labels for ${this.state.items[0].id}`
              }
              visible={this.state.showShipmentDialog}
              style={{width: '80%'}}
              modal
              onHide={() => this.closeShipmentDialog()}
              className="shipment-label-dialog"
            >
              <ShipmentComponent orders={this.state.selectedItems} onHide={this.closeShipmentDialog} />
            </Dialog>
            <EditEcdDialog
              showDialog={this.state.showEditEcdDialog}
              onHide={this.onHideEditEcdDialog}
              orders={this.state.selectedItems}
            />
            {this.props.purchaseOrder && (
              <PurchaseOrderOrderRemoveDialog
                purchaseOrder={this.props.purchaseOrder}
                orders={this.state.selectedItems}
                toast={this.toast}
                showDialog={this.state.showRemoveDialog}
                onHide={this.hideRemoveDialog}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default OrderListComponent;
