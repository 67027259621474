import {DropdownOption} from 'two-core';

export const stages: DropdownOption[] = [
  {label: 'New', value: 'New'},
  {label: 'Printed', value: 'Ready'},
  {label: 'Waiting 4 Material', value: 'Waiting 4 Material'},
  {label: 'Waiting For Return', value: 'Waiting For Return'},
  {label: 'Alu Required', value: 'Alu Required'},
  {label: 'Alu Ordered', value: 'Alu Ordered'},
  {label: 'Alu Needs Cutting', value: 'Alu Needs Cutting'},
  {label: 'Alu Cutting', value: 'Alu Cutting'},
  {label: 'Powder Required', value: 'Powder Required'},
  {label: 'Powder Ordered', value: 'Powder Ordered'},
  {label: 'Sent To Floor', value: 'Sent To Floor'},
  {label: 'In Production', value: 'In Production'},
  {label: 'Post Fix Return', value: 'Post Fix Return'},
  {label: 'Done', value: 'Done'},
  {label: 'Between Factories', value: 'Between Factories'},
  {label: 'Cancelled', value: 'Cancelled'},
  {label: 'On Hold', value: 'On Hold'},
];

export const priorities: DropdownOption[] = [
  {label: 'Low', value: 1},
  {label: 'Normal', value: 2},
  {label: 'High', value: 3},
];

export const types: DropdownOption[] = [
  {label: 'Component', value: 'Component'},
  {label: 'Repair', value: 'Repair'},
  {label: 'Rework', value: 'Rework'},
  {label: 'Sampling', value: 'Sampling'},
  {label: 'Standard', value: 'Standard'},
];

export const supplyItemReorderUnit: DropdownOption[] = [
  {label: 'Linear Meter', value: 'Linear Meter'},
  {label: 'Roll', value: 'Roll'},
  {label: 'Each', value: 'Each'},
  {label: 'Set', value: 'Set'},
  {label: 'Length', value: 'Length'},
  {label: 'Pair', value: 'Pair'},
];

export enum PrintMenuOptions {
  AluCutSheets = 'Alu Cut Shets',
  AluPo = 'Alu PO',
  CutSheets = 'Cut Sheets',
  PowderPO = 'Powder PO',
  ShippingLabels = 'Shipping Labels',
}

export enum StageMenuOptions {
  AluCut = 'Alu Cut',
  AluCutRequested = 'Alu Cut Requested',
  AluDelivered = 'Alu Delivered',
  AluOrdered = 'Alu Ordered',
  CoatedAndDelivered = 'Coated and Delivered',
  Delivered = 'Delivered',
  InProduction = 'In Production',
  NeedsAlu = 'Needs Alu',
  NeedsPowder = 'Needs Powder',
  PowderOrdered = 'Powder Ordered',
  Printed = 'Printed',
  MaterialAvailable = 'Material Available',
  ProductionCompleted = 'Production Complete',
  SendToFloor = 'Send To Floor',
  PostFixDelivery = 'Post Fix Return',
  WaitingForReturn = 'Waiting For Return',
  OnHold = 'On Hold',
  Received = 'Received',
}

export enum OtherMenuOptions {
  CheckStock = 'Check Stock',
  RecordNote = 'Record Note',
  RemoveFromPO = 'Remove from PO',
  SendPickUpEmail = 'Send Pick-up Email',
}
