import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Toast} from 'primereact/toast';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import React from 'react';
import {
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  //  SectionService,
  TwoDataTable,
  AppColumnMenuBodyTemplate,
  TwoMessage,
  ToastService,
  TwoDialog,
  PurchaseOrderPrinterComponent,
} from 'two-app-ui';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import PurchaseOrderAddDialog from './PurchaseOrderAddDialog';
import {NavLink} from 'react-router-dom';
import '../../scss/CustomTable.scss';
import './PurchaseOrders.scss';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import formats from '../../config/formats';
import PurchaseOrderDate from './PurchaseOrderDate';
import {DropdownItemOption} from './Model/PurchaseOrderModel';
import {DropdownOption, PurchaseOrder, PurchaseOrderStage, QueryParameter} from 'two-core';
import SupplierService from '../../services/SuppliersService';
import {purchaseOrderStagesOptions} from './Constants/constants';
import values from '../../config/values';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {MenuItemOptions} from 'primereact/menuitem';
import {faPlusCircle, faPrint, faArrowCircleRight, faPlus, faTimes, faTruck} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import InventoryService from '../../services/InventoryService';
import {DropdownChangeParams} from 'primereact/dropdown';
import {toInputUppercase} from '../Inventory/Constants/Utils';
import {messages} from '../../config/messages';
import PurchaseOrderRecordDeliveryDialog from '../PurchaseOrder/PurchaseOrderRecordDeliveryDialog';

library.add(faPlusCircle, faPlus, faTimes, faPrint, faArrowCircleRight, faTruck);

interface State {
  loading: boolean;
  supplyItems: DropdownItemOption[];
  purchaseOrders: PurchaseOrder[];
  totalPurchaseOrders: number;
  showPurchaseOrderDialog: boolean;
  selectedPurchaseOrders: PurchaseOrder[];
  changeDatePurchaseOrders: PurchaseOrder[];
  activeFilters: {};
  filters: {
    name: string;
    supplier_name: string;
    stage: string;
    sent_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    eta: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    delivered_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  showDateDialog: boolean;
  showRecordDeliveryDialog: boolean;
  currentStage: string;
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
}

class PurchaseOrderListComponent extends React.Component<{}, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;
  purchaseOrdersService: PurchaseOrdersService | null = null;
  supplierService: SupplierService | null = null;
  inventoryService: InventoryService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  constructor() {
    super({});
    this.state = {
      supplyItems: [],
      loading: false,
      purchaseOrders: [],
      totalPurchaseOrders: 0,
      showPurchaseOrderDialog: false,
      selectedPurchaseOrders: [],
      changeDatePurchaseOrders: [],
      activeFilters: {},
      filters: {
        name: '',
        stage: '',
        supplier_name: '',
        sent_at: {
          fromDate: null,
          toDate: null,
        },
        eta: {
          fromDate: null,
          toDate: null,
        },
        delivered_at: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      showDateDialog: false,
      showRecordDeliveryDialog: false,
      currentStage: '',
      sortBy: null,
    };
    this.toast = React.createRef();

    this.saveStageAndDatePurchaseOrders = this.saveStageAndDatePurchaseOrders.bind(this);
    this.stageChange = this.stageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.showRecordDeliveryDialog = this.showRecordDeliveryDialog.bind(this);
    this.showDateDialog = this.showDateDialog.bind(this);
    this.closeDateDialog = this.closeDateDialog.bind(this);
    this.onSort = this.onSort.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.supplierService = this.context.supplierService;
    this.inventoryService = this.context.inventoryService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
        await localStorage.setItem('current factory', castedMessage.value as string);
        this.loadData();
      }
      if (message === messages.purchaseOrderUpdated) {
        this.loadData();
      }
    });

    this.loadData();
  }

  loadData() {
    this.setState({loading: true});

    const filters: string[] = [];

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.stage && this.state.filters.stage.length) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.sent_at.fromDate) {
      const fromDate = this.state.filters.sent_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'sent_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.sent_at.toDate) {
      const toDate = this.state.filters.sent_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'sent_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.eta.fromDate) {
      const fromDate = this.state.filters.eta.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'eta',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.eta.toDate) {
      const toDate = this.state.filters.eta.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'eta',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.delivered_at.fromDate) {
      const fromDate = this.state.filters.delivered_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'delivered_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.delivered_at.toDate) {
      const toDate = this.state.filters.delivered_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'delivered_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.supplier_name && this.state.filters.supplier_name.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'supplier.company_name',
          value: this.state.filters.supplier_name,
          condition: 'like',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    //some columns have different name in database and map for server
    const sortBy = JSON.stringify({
      field: this.state.sortBy?.field ?? 'id',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      orderBys: [sortBy],
      aggregate: true,
      filters: filters,
    };
    this.purchaseOrdersService
      ?.getPurchaseOrders(params)
      .then(data => {
        const purchaseOrders = (data?.records as PurchaseOrder[]) ?? [];
        purchaseOrders.map(
          (po: PurchaseOrder) => (po.related_order_ids = po.related_order_ids?.filter(i => i !== null))
        );

        this.handleSelectedItems(purchaseOrders);
        this.setState({
          purchaseOrders: purchaseOrders,
          totalPurchaseOrders: data?.total_records ?? [],
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = this.state.selectedPurchaseOrders;
    const selectedItemsCount = selectedItems.length;

    this.initNewMenuItem(menuItems);
    this.initSeparatorMenuItem(menuItems);

    if (selectedItemsCount > 0) {
      this.initPrintMenuItems(menuItems);
      this.initSeparatorMenuItem(menuItems);

      console.log('stage', selectedItems[0].stage);
      if (
        selectedItemsCount === 1 &&
        (selectedItems[0].stage === 'Ordered' ||
          selectedItems[0].stage === 'Eta Confirmed' ||
          selectedItems[0].stage === 'Delayed' ||
          selectedItems[0].stage === 'Partially Delivered')
      ) {
        this.initRecordDeliveryMenuItem(menuItems);
        this.initSeparatorMenuItem(menuItems);
      }

      const selectedPoStages = selectedItems.reduce((prevPo, currentPo) => {
        prevPo[currentPo.stage] = prevPo[currentPo.stage] || [];
        prevPo[currentPo.stage].push(currentPo);
        return prevPo;
      }, Object.create(null));

      if (selectedPoStages && selectedPoStages['Draft'] && selectedPoStages['Draft'].length === selectedItemsCount) {
        this.initOrderedMenuItem(menuItems);
      }

      if (
        selectedPoStages &&
        selectedPoStages['Ordered'] &&
        selectedPoStages['Ordered'].length === selectedItemsCount
      ) {
        this.initEtaConfirmedMenuItem(menuItems);
        this.initDelayedMenuItem(menuItems);
      }

      if (
        selectedPoStages &&
        selectedPoStages['ETA Confirmed'] &&
        selectedPoStages['ETA Confirmed'].length === selectedItemsCount
      ) {
        this.initDelayedMenuItem(menuItems);
      }

      if (selectedPoStages && !selectedPoStages['Delivered']) {
        this.initCancelMenuItems(menuItems);
      }
    }

    return menuItems;
  }

  initSeparatorMenuItem(menuItems: AppMenuItem[]) {
    menuItems.push({
      separator: true,
    });
  }

  initNewMenuItem(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'Add New',
      faIcon: faPlusCircle,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.showAddDialog(),
    });
  }

  initPrintMenuItems(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'Print',
      faIcon: faPrint,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.requestDataAndPrint(),
    });
  }

  initRecordDeliveryMenuItem(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'Record Delivery',
      faIcon: faTruck,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.showRecordDeliveryDialog(),
    });
  }

  initOrderedMenuItem(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'Ordered',
      faIcon: faArrowCircleRight,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: async () => this.showDateDialog('Ordered'),
    });
  }

  initEtaConfirmedMenuItem(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'ETA Confirmed',
      faIcon: faArrowCircleRight,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: async () => this.showDateDialog('ETA Confirmed'),
    });
  }

  initDelayedMenuItem(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'Delayed',
      faIcon: faArrowCircleRight,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.saveStage('Delayed'),
    });
  }

  initCancelMenuItems(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'Cancel',
      faIcon: faArrowCircleRight,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.saveStage('Cancelled'),
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  showRecordDeliveryDialog() {
    this.setState({showRecordDeliveryDialog: true});
  }

  closeDateDialog() {
    this.setState({showDateDialog: false});
  }

  showDateDialog(stage: string) {
    const orders = [...this.state.selectedPurchaseOrders];
    const ordersToChangeDate: PurchaseOrder[] = [];
    for (const order of orders) {
      const poOrder = {
        ...order,
        sent_at: order.sent_at
          ? new Date(order.sent_at)
          : stage === 'Ordered' || stage === 'ETA Confirmed' || stage === 'Delivered'
            ? new Date()
            : undefined,

        eta: order.eta
          ? new Date(order.eta)
          : stage === 'ETA Confirmed' || stage === 'Delivered'
            ? new Date()
            : undefined,

        delivered_at: order.delivered_at
          ? new Date(order.delivered_at)
          : stage === 'Delivered'
            ? new Date()
            : undefined,
      };

      ordersToChangeDate.push(poOrder);
    }

    this.setState({
      changeDatePurchaseOrders: ordersToChangeDate,
      showDateDialog: true,
      currentStage: stage,
    });
  }

  async showAddDialog() {
    this.setState({showPurchaseOrderDialog: true});
  }

  async saveStageAndDatePurchaseOrders() {
    this.closeDateDialog();
    const currentStageChange = this.state.currentStage;
    this.saveStage(currentStageChange, this.state.changeDatePurchaseOrders);
  }

  async saveStage(stage: string, purchasingOrders?: PurchaseOrder[]) {
    this.setState({loading: true});

    this.stageChange(stage, purchasingOrders ?? this.state.selectedPurchaseOrders);
  }

  handleChange(purchaseOrder: PurchaseOrder, index: number) {
    const porders = [...this.state.changeDatePurchaseOrders];
    porders[index] = {...purchaseOrder};
    this.setState({changeDatePurchaseOrders: porders});
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  async stageChange(newStage: string, purchasingOrders: PurchaseOrder[]) {
    const promises = purchasingOrders.map(purchaseOrder => {
      purchaseOrder.stage = newStage as PurchaseOrderStage;
      return this.purchaseOrdersService?.updatePurchaseOrder(purchaseOrder.id?.toString() ?? '', purchaseOrder);
    });

    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Purchase Order stage updated succesfully.');
        this.loadData();
        this.setState({
          loading: false,
          selectedPurchaseOrders: [],
          changeDatePurchaseOrders: [],
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, The Purchase Order  stage update failed, please try again.');
        console.error(error);
      });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  async requestDataAndPrint() {
    MessageService.sendMessage(messages.printPurchaseOrders);
  }

  handleSelectedItems(allItems: PurchaseOrder[]) {
    const selectedItems = [...this.state.selectedPurchaseOrders];
    const items: PurchaseOrder[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: PurchaseOrder[]) {
    this.setState({selectedPurchaseOrders: items});
  }

  async setChangeSelectedItem(item: PurchaseOrder) {
    const items = [...this.state.selectedPurchaseOrders];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedPurchaseOrders: items});
    }
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  render() {
    const {showPurchaseOrderDialog} = this.state;
    const stageBodyTemplate = (rowData: PurchaseOrder) => {
      return (
        <span className={`stage-badge stage-po-${(rowData.stage ?? '').toLowerCase().replaceAll(' ', '-')}`}>
          {rowData.stage}
        </span>
      );
    };

    const nameTemplate = (rowData: PurchaseOrder) => {
      return (
        <AppColumnMenuBodyTemplate
          isDynamicMenuItems={true}
          initMenuItems={() => this.initMenuItems()}
          rowItemIdentifier={rowData.id?.toString() ?? ''}
          selectedItems={this.state.selectedPurchaseOrders}
          handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <NavLink to={'purchase-order/' + rowData.id}>{rowData.name}</NavLink>
        </AppColumnMenuBodyTemplate>
      );
    };
    const formattedOrderedDate = (rowData: PurchaseOrder) => {
      return (
        <React.Fragment>
          {rowData.sent_at && DateTime.fromISO(rowData.sent_at.toString()).toFormat(formats.date)}
        </React.Fragment>
      );
    };

    const formattedDeliveredDate = (rowData: PurchaseOrder) => {
      return (
        <React.Fragment>
          {rowData.delivered_at && DateTime.fromISO(rowData.delivered_at.toString()).toFormat(formats.date)}
        </React.Fragment>
      );
    };

    const formattedEtaDate = (rowData: PurchaseOrder) => {
      return (
        <React.Fragment>
          {rowData.eta && DateTime.fromISO(rowData.eta.toString()).toFormat(formats.date)}
        </React.Fragment>
      );
    };

    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );

    const supplierFilter = (
      <InputText
        name="supplier_name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );

    const selectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span
            className={`p-mr-1 stage-badge stage-badge-filter stage-po-${value.toLowerCase().replaceAll(' ', '-')}`}
          >
            {value}
          </span>
        );
      }

      return <></>;
    };

    const itemTemplate = (option: DropdownOption) => {
      const value = option.value ?? '';
      return (
        <span className={`stage-badge stage-po-${(value as string).toLowerCase().replaceAll(' ', '-')}`}>
          {option.value}
        </span>
      );
    };

    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={selectedItemTemplate}
        itemTemplate={itemTemplate}
        value={this.state.filters.stage}
        options={purchaseOrderStagesOptions}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const sendDateFilter = (
      <DateColumnFilter name="sent_at" value={this.state.filters.sent_at} onChange={e => this.onFilterChange(e)} />
    );

    const deliveredDateFilter = (
      <DateColumnFilter
        name="delivered_date"
        value={this.state.filters.delivered_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const etaDateFilter = (
      <DateColumnFilter name="eta" value={this.state.filters.eta} onChange={e => this.onFilterChange(e)} />
    );

    return (
      <div id="purchase_orders_page_container" className="page-container">
        <TwoDataTable
          addNewItemEvent={() => this.showAddDialog()}
          selectedItems={this.state.selectedPurchaseOrders}
          pageSizeIdentifier={'purchase_orders_page_container'}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as PurchaseOrder[])}
          selectionMode="multiple"
          totalRecords={this.state.totalPurchaseOrders}
          first={this.state.pagination.offset}
          value={this.state.purchaseOrders}
          rows={this.state.pagination.pageSize}
          loading={this.state.loading}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={this.onSort}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          activeFilters={this.state.activeFilters}
          initMenuItems={this.initMenuItems}
        >
          <Column
            header="Name"
            field="name"
            body={nameTemplate}
            sortable
            filter
            filterElement={nameFilter}
            style={{width: '300px'}}
            showFilterMenu={false}
            className="col-mix-xxl"
          />
          <Column
            header="Vendor"
            field="supplier.company_name"
            sortable
            filter
            filterElement={supplierFilter}
            style={{width: '150px'}}
            showFilterMenu={false}
            className="col-mix-xxl"
          />
          <Column
            header="Stage"
            field="stage"
            body={stageBodyTemplate}
            sortable
            filter
            filterElement={stageFilter}
            style={{width: '100px'}}
            showFilterMenu={false}
            className="col-xxl"
          />
          <Column
            header="Ordered Date"
            field="sent_at"
            body={formattedOrderedDate}
            sortable
            filter
            filterElement={sendDateFilter}
            style={{width: '50px'}}
            showFilterMenu={false}
            className="col-xl"
          />
          <Column
            header="Eta"
            field="eta"
            body={formattedEtaDate}
            sortable
            filter
            filterElement={etaDateFilter}
            style={{width: '50px'}}
            showFilterMenu={false}
            className="col-xl"
          />
          <Column
            header="Date Delivered"
            field="delivered_at"
            body={formattedDeliveredDate}
            sortable
            filter
            filterElement={deliveredDateFilter}
            style={{width: '50px'}}
            showFilterMenu={false}
            className="col-xl"
          />
        </TwoDataTable>
        <PurchaseOrderAddDialog
          showPurchaseOrderDialog={showPurchaseOrderDialog}
          closeDialog={() => this.setState({showPurchaseOrderDialog: false})}
          toast={this.toast}
        />
        {this.state.selectedPurchaseOrders.length === 1 && (
          <PurchaseOrderRecordDeliveryDialog
            purchaseOrder={this.state.selectedPurchaseOrders[0]}
            showDialog={this.state.showRecordDeliveryDialog}
            onHide={() => this.setState({showRecordDeliveryDialog: false})}
            toast={this.toast}
          />
        )}
        <TwoDialog
          className="purchaser-order-date-dialog"
          headerTitle={'PO[s] Ordered'}
          showDialog={this.state.showDateDialog}
          width={60}
          onHide={this.closeDateDialog}
          onSave={this.saveStageAndDatePurchaseOrders}
          loading={this.state.loading}
        >
          <PurchaseOrderDate purchaseOrders={this.state.changeDatePurchaseOrders} handleChange={this.handleChange} />
        </TwoDialog>
        <PurchaseOrderPrinterComponent
          purchaseOrders={this.state.selectedPurchaseOrders ?? []}
          triggerMessage={messages.printPurchaseOrders}
        />
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default PurchaseOrderListComponent;
