import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {
  ApiListResponse,
  ApiResponse,
  Bom,
  PurchaseOrder,
  PurchaseOrderDelivery,
  PurchaseOrderItem,
  PurchaseOrderItemPatch,
  PurchaseOrderPatch,
  QueryParameter,
  TwoType,
} from 'two-core';

class PurchaseOrdersService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.purchaseOrders as string;
  }

  async getPurchaseOrders(params: QueryParameter): Promise<ApiListResponse> {
    params.filters = params.filters ? params.filters : [];
    params.filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: localStorage.getItem('current factory'),
      })
    );

    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async getPurchaseOrder(id: string): Promise<PurchaseOrder> {
    return this.get(this.endpoint + '/' + id)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve((data as ApiResponse).body as PurchaseOrder);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createPurchaseOrder(params: PurchaseOrder): Promise<PurchaseOrder> {
    params.factory_id = localStorage.getItem('current factory') ?? '';
    return this.post(this.endpoint, params)
      .then((response: TwoType) => {
        return Promise.resolve(response as PurchaseOrder);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updatePurchaseOrder(id: string, params: PurchaseOrderPatch): Promise<PurchaseOrder> {
    return this.patch(this.endpoint + '/' + id, params)
      .then((response: TwoType) => {
        return Promise.resolve(response as PurchaseOrder);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async recordPurchaseOrderDelivery(id: string, deliveryRecord: PurchaseOrderDelivery): Promise<PurchaseOrder> {
    return this.patch(`${this.endpoint}/${id}/deliveries`, deliveryRecord)
      .then((response: TwoType) => {
        return Promise.resolve(response as PurchaseOrder);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async getPurchaseOrderItems(purchaseOrderId: string, params: QueryParameter): Promise<ApiListResponse> {
    return this.get(`${this.endpoint}/${purchaseOrderId}/items`, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async getPurchaseOrderOrders(purchaseOrderId: string, params: QueryParameter): Promise<ApiListResponse> {
    return this.get(`${this.endpoint}/${purchaseOrderId}/orders`, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async getPurchaseOrderItemsForOrder(purchaseOrderId: string, orderId: string): Promise<ApiListResponse> {
    //purchase-orders/{id}/orders/{order_id}/items
    return this.get(`${this.endpoint}/${purchaseOrderId}/orders/${orderId}/items`)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async getPurchaseOrderItemOrders(purchaseOrderId: string, itemId: string): Promise<ApiListResponse> {
    //purchase-orders/{id}/items/{item_id}/orders
    return this.get(`${this.endpoint}/${purchaseOrderId}/items/${itemId}/orders`)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updatePurchaseOrderItem(
    purchaseOrderId: string,
    purchaseOrderItemId: number,
    item2patch: PurchaseOrderItemPatch
  ): Promise<PurchaseOrderItem> {
    return this.patch(`${this.endpoint}/${purchaseOrderId}/items/${purchaseOrderItemId}`, item2patch)
      .then((response: TwoType) => {
        return Promise.resolve(response as PurchaseOrderItem);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createPurchaseOrderItem(purchaseOrderId: string, item2create: PurchaseOrderItem): Promise<PurchaseOrderItem> {
    return this.post(`${this.endpoint}/${purchaseOrderId}/items`, item2create)
      .then((response: TwoType) => {
        return Promise.resolve(response as PurchaseOrderItem);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deletePurchaseOrderItem(purchaseOrderId: string, purchaseOrderItemId: number): Promise<void> {
    return this.delete(`${this.endpoint}/${purchaseOrderId}/items/${purchaseOrderItemId}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async assignOrderToPurchaseOrder(purchaseOrderId: string, orderId: string): Promise<Bom> {
    return this.post(`${this.endpoint}/${purchaseOrderId}/orders`, {
      order_id: orderId,
    })
      .then((response: TwoType) => {
        return Promise.resolve(response as Bom);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async generateMissingPurchaseOrderItems(forPoId: string): Promise<ApiListResponse> {
    return this.get(`${this.endpoint}/${forPoId}/items/missing`)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}
export default PurchaseOrdersService;
