import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, FactoryOrder, FactoryOrderPatch} from 'two-core';
import {DateTime} from 'luxon';
import {ProductionStageM2cAuShades} from 'two-core';
import {ProductionStageM2cUsRanch, ProductionStageM2CAote} from 'two-core';

class FactoryOrderService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.factoryOrders ?? '';
  }

  async updateFactoryOrder(id: string, data: FactoryOrderPatch): Promise<FactoryOrder> {
    data.ecd = typeof data.ecd === 'string' ? DateTime.fromISO(data.ecd, {zone: 'utc'}).toJSDate() : data.ecd;

    if (data.production_stage && (data.production_stage === 'Done' || data.production_stage === 'Post Fix Return')) {
      data.completed_at = new Date();
    }

    return this.patch(this.endpoint + '/' + id, data)
      .then(data => {
        return Promise.resolve(data as FactoryOrder);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async doStockUpdate(
    previousProductionOrderStage: ProductionStageM2cAuShades | ProductionStageM2cUsRanch | ProductionStageM2CAote,
    productionOrderStage: ProductionStageM2cAuShades | ProductionStageM2cUsRanch | ProductionStageM2CAote,
    orderId: string
  ) {
    if (productionOrderStage === 'Ready' || productionOrderStage === 'Waiting 4 Material') {
      if (previousProductionOrderStage === 'New') {
        console.log('reserve ', orderId);
        return this.patch(this.endpoint + '/' + orderId + '/reserve_stock', '');
      }
    } else if (
      productionOrderStage === 'Init' ||
      productionOrderStage === 'New' ||
      productionOrderStage === 'Waiting For Return' ||
      productionOrderStage === 'Cancelled'
    ) {
      if (
        previousProductionOrderStage === 'Init' ||
        previousProductionOrderStage === 'New' ||
        previousProductionOrderStage === 'Waiting For Return' ||
        previousProductionOrderStage === 'Cancelled'
      ) {
        // do nothing
      } else {
        console.log('unReserveStock ', orderId);
        return this.patch(this.endpoint + '/' + orderId + '/un_reserve_stock', '');
      }
    } else if (productionOrderStage === 'Post Fix Return' || productionOrderStage === 'Done') {
      if (previousProductionOrderStage === 'In Production' || previousProductionOrderStage === 'Sent To Floor') {
        console.log('deductStock ', orderId);
        return this.patch(this.endpoint + '/' + orderId + '/deduct_stock', '');
      }
    }
    return Promise.resolve();
  }

  async checkStockFor(orderIds: string[]): Promise<ApiListResponse> {
    return this.get(`${this.endpoint}/check_stock`, {
      filters: [
        JSON.stringify({
          field: 'order_ids',
          value: orderIds,
        }),
      ],
    })
      .then(data => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}

export default FactoryOrderService;
