import React from 'react';
import {DataTablePageParams} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faPen} from '@fortawesome/pro-regular-svg-icons';
import {Order, QueryParameter, ShipmentItem} from 'two-core';
import {
  TwoDataTable,
  AppColumnMenuBodyTemplate,
  AppMenuItem,
  AppMenuItemTemplate,
  AppContext,
  ToastService,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';

import {Toast} from 'primereact/toast';
import ShipmentsService from '../../services/ShipmentsService';
import FactoriesService from '../../services/FactoriesService';
import ShipmentComponent from './ShipmentComponent';
import {Dialog} from 'primereact/dialog';
import {ProgressSpinner} from 'primereact/progressspinner';

library.add(faPen);

interface Props {
  orders: Order[];
  count?: number;
  selectable?: boolean;
  hideId?: boolean;
}

interface State {
  loading: boolean;
  showShipmentDialog: boolean;
  pagination: {pageSize: number; offset: number};
  items: ShipmentItem[];
  totalItems: number;
  selectedItems: ShipmentItem[];
}
class ShipmentItemListComponent extends React.Component<Props, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;

  shipmentsService: ShipmentsService | null;
  factoriesService: FactoriesService | null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      showShipmentDialog: false,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      items: [],
      totalItems: 0,
      selectedItems: [],
    };

    this.toast = React.createRef();
    this.shipmentsService = null;
    this.factoriesService = null;

    this.initMenuItems = this.initMenuItems.bind(this);
    this.closeShipmentDialog = this.closeShipmentDialog.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
  }

  async componentDidMount() {
    this.shipmentsService = this.context.shipmentsService;
    this.factoriesService = this.context.factoriesService;
    this.toastService = this.context.toastService;

    await this.loadData();
  }

  async loadData() {
    this.setState({loading: true});

    const orderIds = this.props.orders.map(order => {
      if (!order.id) {
        throw new Error('invalid order');
      }
      return order.id;
    });

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'order_id',
        value: orderIds,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      orderBys: [
        JSON.stringify({field: 'count', direction: 'ASC'}),
        JSON.stringify({field: 'index', direction: 'ASC'}),
      ],
      aggregate: true,
    };

    return this.shipmentsService
      ?.getShippingLabels(params)
      .then(response => {
        console.log(response);
        this.setState({
          items: response.records as ShipmentItem[],
          totalItems: response.total_records,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({loading: false});

        this.toastService?.showError(this.toast, 'Sorry, shipment items loading failed, please try again.');
        console.error('error: ' + error);
      });
  }

  initMenuItems(): AppMenuItem[] {
    const items = this.state.items;
    const menuItems: AppMenuItem[] = [];
    if (items.length > 0) {
      menuItems.push({
        label: 'Edit',
        faIcon: faPen,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.setState({showShipmentDialog: true});
        },
      });
    }
    return menuItems;
  }

  async setChangeSelectedItems(shipmentItems: ShipmentItem[]) {
    this.setState({selectedItems: shipmentItems});
  }

  async setChangeSelectedItem(item: ShipmentItem): Promise<void> {
    const items: ShipmentItem[] = [...(this.state.selectedItems as ShipmentItem[])];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
    }
    return this.setState({selectedItems: items});
  }

  closeShipmentDialog() {
    this.loadData();
    this.setState({showShipmentDialog: false});
  }

  render() {
    const {loading, selectedItems, pagination, items} = this.state;

    const onPageChange = (e: DataTablePageParams) => {
      this.setState({pagination: {offset: e.first, pageSize: e.rows}});
      this.loadData();
    };

    const idBodyTemplate = (rowData: ShipmentItem) => {
      return (
        <React.Fragment>
          <AppColumnMenuBodyTemplate
            rowItemIdentifier={rowData?.id?.toString() ?? ''}
            isDynamicMenuItems={true}
            initMenuItems={this.initMenuItems}
            selectedItems={selectedItems}
            handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
          >
            {`${rowData.id}`}
          </AppColumnMenuBodyTemplate>
        </React.Fragment>
      );
    };

    const indexBodyTemplate = (rowData: ShipmentItem) => {
      return this.props.hideId ? (
        <React.Fragment>
          <AppColumnMenuBodyTemplate
            rowItemIdentifier={rowData?.id?.toString() ?? ''}
            isDynamicMenuItems={true}
            initMenuItems={this.initMenuItems}
            selectedItems={selectedItems}
            handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
          >
            {`${rowData.index}`}
          </AppColumnMenuBodyTemplate>
        </React.Fragment>
      ) : (
        rowData.index
      );
    };

    return (
      <div id="shipment_item_page_container" className="page-container">
        {!loading ? (
          <TwoDataTable
            pageSizeIdentifier={'shipment_item_list_page_container'}
            sizeIdentifiers={['filter-box']}
            selectionMode={this.props.selectable ? 'multiple' : undefined}
            selectedItems={selectedItems}
            rows={pagination.pageSize}
            first={pagination.offset}
            onPage={e => onPageChange(e as DataTablePageParams)}
            loading={loading}
            value={items}
            totalRecords={this.state.totalItems}
            className="p-datatable-sm"
            activeFilters={[]}
            initMenuItems={this.initMenuItems}
          >
            <Column
              style={{width: '10px'}}
              key="id"
              field="id"
              header="Id"
              body={idBodyTemplate}
              hidden={this.props.hideId}
            />

            <Column style={{width: '10px'}} key="index" field="index" header="Index" body={indexBodyTemplate} />
            <Column style={{width: '10px'}} key="count" field="count" header="Of" />
            <Column style={{width: '10px'}} key="content" field="content" header="Content" />
            <Column style={{width: '10px'}} key="width" field="dimensions.width" header="Width" />
            <Column style={{width: '10px'}} key="height" field="dimensions.height" header="Height" />
            <Column style={{width: '10px'}} key="depth" field="dimensions.depth" header="Depth" />
            <Column style={{width: '20px'}} key="weight" field="dimensions.weight" header="Weight" />
            <Column style={{width: '100px', padding: '0px'}} key="ext_label" field="ext_label" header="FF2 Label" />
          </TwoDataTable>
        ) : (
          <div className="p-d-flex p-flex-row p-ai-center">
            <ProgressSpinner />
          </div>
        )}
        <Dialog
          header={`Shipping Labels for ${this.props.orders[0].id}`}
          visible={this.state.showShipmentDialog}
          style={{width: '80%'}}
          modal
          onHide={() => this.closeShipmentDialog()}
          className="shipment-label-dialog"
        >
          <ShipmentComponent orders={this.props.orders} onHide={this.closeShipmentDialog} />
        </Dialog>
      </div>
    );
  }
}

export default ShipmentItemListComponent;
