import React from 'react';
import {Order, PurchaseOrder, PurchaseOrderItem, QueryParameter, SupplyItem} from 'two-core';
import OrdersService from '../../services/OrdersService';
import {DataTableDataSelectableParams, DataTablePageParams} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {AppContext, ToastService, TwoDataTable} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import './GroupOrderComponents.scss';

interface PurchaseOrderItemIndex {
  index: number;
  item: PurchaseOrderItem;
  selectedOrders: Order[];
}

interface Props {
  purchaseOrderItemIndex: PurchaseOrderItemIndex;
  purchaseOrder: PurchaseOrder;
  toast: React.RefObject<Toast>;
  supplyItems: SupplyItem[];
  handleSelectedOrdersChange: (changedPoItemIndex: PurchaseOrderItemIndex) => void;
}

interface State {
  orders: Order[];
  totalItems: number;
  selectedOrders: Order[];
  pagination: {
    pageSize: number;
    offset: number;
  };
  loading: boolean;
  remainingQty: number;
}

class GroupOrderComponents extends React.Component<Props, State> {
  static contextType = AppContext;
  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      orders: [],
      totalItems: 0,
      selectedOrders: [],
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      loading: true,
      remainingQty: 0,
    };
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
    this.loadOrders();
  }

  loadOrders() {
    this.setState({loading: true});
    const orderFilters: string[] = [
      JSON.stringify({
        field: 'bom.inventory_item_id',
        value: this.props.purchaseOrderItemIndex.item.inventory_item_id,
      }),
      JSON.stringify({
        field: 'purchase_order.id',
        value: this.props.purchaseOrder.id,
      }),
    ];

    const params: QueryParameter = {
      filters: orderFilters,
      aggregate: true,
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
    };

    this.ordersService
      ?.getOrders(params)
      .then(data => {
        const orders = data.records as Order[];
        const qtyFromSelected = this.props.purchaseOrderItemIndex.selectedOrders.reduce(
          (total, currentValue) => (total = total + (currentValue.boms ? currentValue.boms[0].quantity : 0)),
          0
        );
        this.setState({
          orders: orders,
          totalItems: data?.total_records ?? 0,
          loading: false,
          remainingQty: qtyFromSelected === 0 ? this.getRemainingQty() : this.getRemainingQty() - qtyFromSelected,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
      });
  }

  getRemainingQty() {
    const {supplyItems} = this.props;
    const purchaseOrderItem = this.props.purchaseOrderItemIndex.item;
    const supplyItem = supplyItems.filter(
      supplyItem => '' + supplyItem.id === '' + purchaseOrderItem.supply_item_id
    )[0];

    return purchaseOrderItem.quantity * supplyItem?.reorder_qty_in_uom ?? 0;
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadOrders();
  }

  setChangeSelectedItems(items: Order[]) {
    const newQty = items.reduce(
      (total, currentValue) => (total = total + (currentValue.boms ? currentValue.boms[0].quantity : 0)),
      0
    );
    this.setState({
      selectedOrders: items,
      remainingQty: this.getRemainingQty() - newQty,
    });
    const changedItemIndex = this.props.purchaseOrderItemIndex;
    changedItemIndex.selectedOrders = items;

    this.props.handleSelectedOrdersChange(changedItemIndex);
  }

  render() {
    const otherPOsTemplate = (rowData: Order) => {
      const pos = rowData?.purchase_orders_ids?.filter(id => id !== this.props.purchaseOrder.id) ?? [];
      return <span>{pos ? pos.join(', ') : ''}</span>;
    };

    const qtyTemplate = (rowData: Order) => {
      return <span>{rowData.boms ? rowData.boms[0].quantity : ''}</span>;
    };

    const isRowSelectable = (params: DataTableDataSelectableParams) => {
      const isSelected = this.props.purchaseOrderItemIndex.selectedOrders.filter(order => order.id === params.data.id);
      return isSelected.length ? true : this.state.remainingQty >= params.data.boms[0].quantity;
    };

    const rowClassName = (data: Order) => {
      return !isRowSelectable({data: data, index: 1}) ? 'disabled-row' : '';
    };

    return (
      <div id="order_list_page_container" className="page-container">
        <TwoDataTable
          key={this.props.purchaseOrderItemIndex.item.purchase_order_id}
          id={this.props.purchaseOrderItemIndex.item.purchase_order_id}
          sizeIdentifiers={[]}
          value={this.state.orders}
          activeFilters={{}}
          selectedItems={this.props.purchaseOrderItemIndex.selectedOrders}
          totalRecords={this.state.totalItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          onPage={e => this.onPageChange(e)}
          handleChangeSelectedItems={items => {
            this.setChangeSelectedItems(items as unknown as Order[]);
          }}
          heightToScroll={undefined}
          selection
          selectionMode="multiple"
          showSelectAll={false}
          loading={this.state.loading}
          isDataSelectable={isRowSelectable}
          rowClassName={rowClassName}
        >
          <Column key="id" field="id" header="Order Code" />
          <Column key="reference" field="reference" header="Reference" />
          <Column key="customer" field="owner_company.account_number" header="Customer" />
          <Column key="type" field="type" header="Type" />
          <Column key="priority" field="priority" header="Prio" />
          <Column key="purchase_orders" field="purchase_orders_ids" header="Other POs" body={otherPOsTemplate} />
          <Column key="qty" field="qty" header="Required Qty" body={qtyTemplate} />
        </TwoDataTable>
      </div>
    );
  }
}

export default GroupOrderComponents;
