import {ProductionLabelData} from './ProductionLabelData';

export class ProductionLabelMaker {
  product: string;
  station: string;
  bay: string;
  code: string;
  reference: string;
  reference2?: string;
  type: string;
  index: string;

  constructor(
    product: string,
    station: string,
    bay: string,
    code: string,
    reference: string,
    type: string,
    index: string
  ) {
    const productFormatted = product.toUpperCase();
    this.product = productFormatted.length > 29 ? `${productFormatted.substring(0, 29)}..` : productFormatted;
    const stationFormatted = station.toUpperCase();
    this.station = stationFormatted.length > 29 ? `${stationFormatted.substring(0, 29)}..` : stationFormatted;
    const bayFormatted = `${bay}`.toUpperCase();
    this.bay = bayFormatted.length > 3 ? bayFormatted.substring(0, 3) : bayFormatted;
    const codeFormatted = `${code}`.toUpperCase();
    this.code = codeFormatted.length > 16 ? codeFormatted.substring(0, 16) : codeFormatted;

    const referenceFormatted = reference.toUpperCase();
    const referenceCut2Size = this.breakUpIfNecessary(referenceFormatted, 34);
    this.reference = referenceCut2Size[0];
    this.reference2 = referenceCut2Size[1];

    const formattedType = type.toUpperCase();
    this.type = formattedType.length > 34 ? formattedType.substring(0, 34) : formattedType;
    const indexFormatted = index.toUpperCase();
    this.index = indexFormatted.length > 14 ? indexFormatted.substring(0, 14) : indexFormatted;
  }

  makeLabelOneLine(d11: string, d12: string, taskId: number, type?: string): ProductionLabelData {
    let labelType = this.type;
    if (type) {
      const typeFormatted = type.toUpperCase();
      labelType = typeFormatted.length > 34 ? typeFormatted.substring(0, 34) : typeFormatted;
    }
    const [data11A, data11B] = this.breakUpIfNecessary(`${d11}`.toUpperCase(), 23);
    const [data12A, data12B] = this.breakUpIfNecessary(`${d12}`.toUpperCase(), 23);
    return {
      h_product: this.product,
      h_station: this.station,
      bay: this.bay,
      order_code: this.code,
      order_reference_1: this.reference,
      order_reference_2: this.reference2,
      task_type: labelType,
      item_index: this.index,
      task_id: taskId,
      data_1_1_A: data11A,
      data_1_1_B: data11B,
      data_1_2_A: data12A,
      data_1_2_B: data12B,
    };
  }

  makeLabelTwoLines(
    d11: string,
    d12: string,
    d21: string,
    d22: string,
    taksId: number,
    type?: string
  ): ProductionLabelData {
    let labelType = this.type;
    if (type) {
      const typeFormatted = type.toUpperCase();
      labelType = typeFormatted.length > 34 ? typeFormatted.substring(0, 34) : typeFormatted;
    }
    const [data11A, data11B] = this.breakUpIfNecessary(`${d11}`.toUpperCase(), 23);
    const [data12A, data12B] = this.breakUpIfNecessary(`${d12}`.toUpperCase(), 23);
    const [data21A, data21B] = this.breakUpIfNecessary(`${d21}`.toUpperCase(), 23);
    const [data22A, data22B] = this.breakUpIfNecessary(`${d22}`.toUpperCase(), 23);
    return {
      h_product: this.product,
      h_station: this.station,
      bay: this.bay,
      order_code: this.code,
      order_reference_1: this.reference,
      order_reference_2: this.reference2,
      task_type: labelType,
      item_index: this.index,
      task_id: taksId,
      data_1_1_A: data11A,
      data_1_1_B: data11B,
      data_1_2_A: data12A,
      data_1_2_B: data12B,
      data_2_1_A: data21A,
      data_2_1_B: data21B,
      data_2_2_A: data22A,
      data_2_2_B: data22B,
    };
  }

  protected breakUpIfNecessary(text: string, maxLength: number): [string, string?] {
    let result1: string;
    let result2: string | undefined;
    if (text.length > maxLength) {
      result1 = text.substring(0, maxLength);
      result2 = text.substring(maxLength, Math.min(text.length, maxLength * 2));
    } else {
      result1 = text;
    }
    return [result1, result2];
  }
}
