import React, {Component} from 'react';
import {SchedulerFilter, SchedulerFilterProps} from './SchedulerFilter';
import {SchedulerPaginationProps, SchedulerTable} from './SchedulerTable';
import {
  ApiListResponse,
  CutSheetReport,
  FactoryOrderPatch,
  Order,
  OrderAggregate,
  OrderPatch,
  ProductionStageM2cAuShades,
  QueryParameter,
  TimeLineEvent,
  User,
} from 'two-core';
import OrdersService from '../../services/OrdersService';
import {AppContext, MessageService, ToastService, TwoDialog, TwoToast} from 'two-app-ui';
import './Scheduler.scss';
import DocumentsService from '../../services/DocumentsService';
import FactoriesService from '../../services/FactoriesService';
import ProductionLabelsDialog from '../ProductionLabels/ProductionLabelsDialog';
import {getFactoryOrdersMenuItems, getNewProductionStagesPatch} from '../../utils/FactoryOrderUtil';
import PurchaseOrderAddDialog from '../PurchaseOrders/PurchaseOrderAddDialog';
import {Toast} from 'primereact/toast';
import ShipmentComponent from '../Shipment/ShipmentComponent';
import {Dialog} from 'primereact/dialog';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import FactoryOrdersService from '../../services/FactoryOrdersService';
import {messages} from '../../config/messages';
import OrderStockDialog from '../OrderStock/OrderStockDialog';
import ToOnHoldDialog from '../Orders/ToOnHoldDialog';
import OrderNoteDialog from '../Order/OrderNoteDialog';
import CuttingSheets from '../CuttingSheets/CuttingSheets';
import {EditEcdDialog} from '../Order/EditEcdDialog';

export type SchedulerMode = 'scheduler' | 'completed';
interface Props {
  mode: SchedulerMode;
}

interface State {
  loading: boolean;
  lazyLoading: boolean;
  userLoading: boolean;
  orders: Order[];
  selectedOrders: Order[];
  collapsedRows: string[];
  totalOrders: number;
  factoryOrdersSizeSum: number;
  filter: SchedulerFilterProps;
  pagination: SchedulerPaginationProps;
  printableData?: CutSheetReport[];
  productionLabelsPrinterIp?: string;
  showProductionLabelsDialog: boolean;
  prePowderOnly: boolean;
  showPurchaseOrderDialog: boolean;
  showShippingLabelsDialog: boolean;
  showHoldDialog: boolean;
  showRecordNoteDialog: boolean;
  showEditEcdDialog: boolean;
  holdReason: string;
  usersMap: Map<string, User>;
}
export class Scheduler extends Component<Props, State> {
  static contextType = AppContext;
  documentsService?: DocumentsService;
  ordersService?: OrdersService;
  factoriesService?: FactoriesService;
  twoToast?: TwoToast;
  toastService?: ToastService;
  factoryOrdersService?: FactoryOrdersService;

  typingTimer?: NodeJS.Timeout;
  toast: React.RefObject<Toast> = React.createRef();

  constructor(props: Props) {
    super(props);

    let completedAt = undefined;
    let completedAtFrom = undefined;
    let completedAtTo = undefined;
    let pagination: SchedulerPaginationProps | undefined = {};
    if (props.mode === 'completed') {
      completedAt = DateTime.fromJSDate(new Date()).setZone('utc', {keepLocalTime: true});
      completedAtFrom = completedAt.startOf('month');
      completedAtTo = completedAt.endOf('month');
      pagination = {
        pageSize: 50,
        offset: 0,
      };
    }
    this.state = {
      loading: false,
      lazyLoading: false,
      userLoading: false,
      orders: [],
      selectedOrders: [],
      filter: {
        productLine: 'Colourvue',
        types: ['Standard', 'Component', 'Repair'],
        completedAt,
        completedAtFrom,
        completedAtTo,
      },
      pagination,
      totalOrders: 0,
      factoryOrdersSizeSum: 0,
      showProductionLabelsDialog: false,
      prePowderOnly: false,
      showPurchaseOrderDialog: false,
      showShippingLabelsDialog: false,
      showHoldDialog: false,
      showRecordNoteDialog: false,
      showEditEcdDialog: false,
      holdReason: '',
      usersMap: new Map<string, User>(),
      collapsedRows: [],
    };

    this.loadData = this.loadData.bind(this);
    this.loadProductionPrinterIp = this.loadProductionPrinterIp.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onCompletedOrdersLazyLoad = this.onCompletedOrdersLazyLoad.bind(this);
    this.onSchedulerOrdersLazyLoad = this.onSchedulerOrdersLazyLoad.bind(this);
    this.onTypingTimerClear = this.onTypingTimerClear.bind(this);
    this.onTypingTimerStart = this.onTypingTimerStart.bind(this);
    this.onOrderSelect = this.onOrderSelect.bind(this);
    this.onPrintCutSheets = this.onPrintCutSheets.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.onHideProductionLabelsDialog = this.onHideProductionLabelsDialog.bind(this);
    this.onShowPurchaseOrderDialog = this.onShowPurchaseOrderDialog.bind(this);
    this.onHidePurchaseOrderDialog = this.onHidePurchaseOrderDialog.bind(this);
    this.onShowShippingLabelsDialog = this.onShowShippingLabelsDialog.bind(this);
    this.onHideShippingLabelsDialog = this.onHideShippingLabelsDialog.bind(this);
    this.onStageChange = this.onStageChange.bind(this);
    this.onStageChangeWithSummaryReplaced = this.onStageChangeWithSummaryReplaced.bind(this);
    this.replaceSummaryOfOrders = this.replaceSummaryOfOrders.bind(this);
    this.handleStageChangeRequest = this.handleStageChangeRequest.bind(this);
    this.updateFactoryOrder = this.updateFactoryOrder.bind(this);
    this.saveOrders = this.saveOrders.bind(this);
    this.onProductionComplete = this.onProductionComplete.bind(this);
    this.onReceived = this.onReceived.bind(this);
    this.onShowAndContinueOrderStockAvailabilityCheckDialog =
      this.onShowAndContinueOrderStockAvailabilityCheckDialog.bind(this);
    this.onStageChangeWithPostFixReturnSetting = this.onStageChangeWithPostFixReturnSetting.bind(this);
    this.setPostFixReturn = this.setPostFixReturn.bind(this);
    this.onShowHoldDialog = this.onShowHoldDialog.bind(this);
    this.onHideHoldDialog = this.onHideHoldDialog.bind(this);
    this.onHoldStageChange = this.onHoldStageChange.bind(this);
    this.onHoldReasonChange = this.onHoldReasonChange.bind(this);
    this.onShowOrderStockAvailabilityCheckDialog = this.onShowOrderStockAvailabilityCheckDialog.bind(this);
    this.onShowRecordNoteDialog = this.onShowRecordNoteDialog.bind(this);
    this.onHideRecordNoteDialog = this.onHideRecordNoteDialog.bind(this);
    this.onOrdersSelectionChange = this.onOrdersSelectionChange.bind(this);
    this.onUserCheck = this.onUserCheck.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.setCollapsedRows = this.setCollapsedRows.bind(this);
    this.onShowEditEcdDialog = this.onShowEditEcdDialog.bind(this);
    this.onHideEditEcdDialog = this.onHideEditEcdDialog.bind(this);
    this.injectLastNotes = this.injectLastNotes.bind(this);
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.documentsService = this.context.documentsService;
    this.factoriesService = this.context.factoriesService;
    this.twoToast = this.context.twoToast;
    this.toastService = this.context.toastService;
    this.factoryOrdersService = this.context.factoryOrdersService;
    this.loadData();
  }

  componentWillUnmount() {
    this.onTypingTimerClear();
  }

  async loadData() {
    const {mode} = this.props;
    const {filter, pagination} = this.state;
    this.setState({loading: true});
    let orders: Order[] = [];
    let totalOrders = 0;
    let newPagination: SchedulerPaginationProps | undefined = {};
    if (mode === 'completed') {
      const result = await this.loadOrders(filter, 0, mode, pagination?.pageSize);
      orders = result.orders;
      totalOrders = result.totalOrders;
      newPagination = {...pagination, offset: pagination.pageSize};
    } else if (mode === 'scheduler') {
      //get last ecd date from factory orders or filter
      const lastFutureEcdDate = await this.loadLastEcdDate(filter, mode);
      // const lastDay = filter.ecdTo && filter.ecdTo < lastFutureEcdDate ? filter.ecdTo : lastFutureEcdDate;
      let lastDay = undefined;
      if (lastFutureEcdDate) {
        lastDay = filter.ecdTo && filter.ecdTo < lastFutureEcdDate ? filter.ecdTo : lastFutureEcdDate;
      } else {
        const endOfTodayDate = DateTime.now().endOf('day').setZone('utc', {keepLocalTime: true});
        lastDay = filter.ecdTo && filter.ecdTo < endOfTodayDate ? filter.ecdTo : endOfTodayDate;
      }
      console.debug('lastDay', lastDay.toISO());
      //get overdue first
      const yesterdayDate = DateTime.now().endOf('day').minus({days: 1}).setZone('utc', {keepLocalTime: true});
      const newEcdTo = lastDay < yesterdayDate ? lastDay : yesterdayDate;
      const injectedFilter = {...filter, ecdTo: newEcdTo};
      const result = await this.loadOrders(injectedFilter, 0, mode);
      //orders in overdue but also in day segments by ecd ASC and then by type Repair, Component, Standard.
      orders = this.sortOrdersByType(result.orders);
      newPagination = {lastLoadedEcdDate: newEcdTo, lastEcdDate: lastDay};
    }

    const ordersSizeSum = await this.loadFactoryOrdersSizeSum(filter, mode);

    this.setState({
      orders,
      selectedOrders: [],
      loading: false,
      pagination: newPagination,
      totalOrders,
      factoryOrdersSizeSum: ordersSizeSum,
      collapsedRows: [],
    });
  }

  async loadUser(userId: string) {
    try {
      this.setState({userLoading: true});
      return await this.context.usersService.getUser(userId);
    } catch (error) {
      this.twoToast?.showError('User not found');
      console.error('User not found', error);
      return undefined;
    } finally {
      this.setState({userLoading: false});
    }
  }

  async loadOrders(filters: SchedulerFilterProps, offset: number, mode: SchedulerMode, pageSize?: number) {
    const orderBys = [];
    if (mode === 'scheduler') {
      orderBys.push(JSON.stringify({field: 'factory_order.ecd', direction: 'ASC'}));
    } else if (mode === 'completed') {
      orderBys.push(JSON.stringify({field: 'factory_order.completed_at', direction: 'ASC'}));
    }

    orderBys.push(JSON.stringify({field: 'type', direction: 'ASC'}));
    orderBys.push(JSON.stringify({field: 'reference', direction: 'ASC'}));

    const aggregate: OrderAggregate[] = ['factory_order', 'owner_company', 'purchase_orders', 'last_tle'];
    const params: QueryParameter = {
      aggregate,
      filters: this.geOrdersQueryFilters(filters, mode),
      orderBys,
      page_size: pageSize,
      offset,
    };
    return this.ordersService!.getOrders(params)
      .then(data => {
        return {orders: data.records as Order[], totalOrders: data.total_records as number};
      })
      .catch(e => {
        return {orders: [] as Order[], totalOrders: 0};
      });
  }

  async loadLastEcdDate(filters: SchedulerFilterProps, mode: SchedulerMode): Promise<DateTime | undefined> {
    if (mode !== 'scheduler') {
      return undefined;
    }
    try {
      const injectedFilters = {...filters};
      if (!filters.ecdFrom) {
        injectedFilters.ecdFrom = DateTime.now().startOf('day').setZone('utc', {keepLocalTime: true});
      }
      const params: QueryParameter = {
        filters: this.geOrdersQueryFilters(injectedFilters, mode),
        page_size: 1,
        orderBys: [JSON.stringify({field: 'factory_order.ecd', direction: 'DESC'})],
        aggregate: ['factory_order'],
      };
      const response = (await this.ordersService!.getOrders(params)) as ApiListResponse;
      const lastOrder = (response?.records as Order[])?.[0];
      const lastEcd = lastOrder?.factory_order?.ecd;
      if (!lastEcd) {
        return undefined;
      }
      return DateTime.fromISO(lastEcd.toString()).endOf('day').setZone('utc', {keepLocalTime: true});
    } catch (e) {
      console.error('Error while loading last ECD date', e);
      return undefined;
    }
  }

  async loadFactoryOrdersSizeSum(filters: SchedulerFilterProps, mode: SchedulerMode) {
    const params: QueryParameter = {
      filters: this.geOrdersQueryFilters(filters, mode),
    };
    return this.ordersService!.getFactoryOrdersSizeSum(params)
      .then(data => {
        return Number(data);
      })
      .catch(e => {
        return 0;
      });
  }

  async loadProductionPrinterIp(currentFactoryId: string) {
    return this.factoriesService
      ?.getFactory(currentFactoryId)
      .then(data => {
        return data.settings?.printers?.production_label_printer_ip ?? '';
      })
      .catch(error => {
        this.twoToast?.showError('Production Label Printer IP not found');
        return undefined;
      });
  }

  /**
   * @returns New array of sorted orders
   * @param orders
   */
  sortOrdersByType(orders: Order[], checkEcdDates?: boolean): Order[] {
    const sortTypeOrder = ['Repair', 'Component', 'Standard'];
    return [...orders].sort((a, b) => {
      if (checkEcdDates) {
        if (a.factory_order?.ecd && b.factory_order?.ecd) {
          const aEcd = DateTime.fromISO(a.factory_order.ecd.toString());
          const bEcd = DateTime.fromISO(b.factory_order.ecd.toString());
          if (aEcd < bEcd) {
            return -1;
          }
          if (aEcd > bEcd) {
            return 1;
          }
        }
      }
      const aIndex = sortTypeOrder.indexOf(a.type);
      const bIndex = sortTypeOrder.indexOf(b.type);
      return aIndex - bIndex;
    });
  }

  initMenuItems() {
    const {selectedOrders} = this.state;
    return getFactoryOrdersMenuItems(selectedOrders, {
      onPrintCutSheets: this.onPrintCutSheets,
      onShowPurchaseOrderDialog: this.onShowPurchaseOrderDialog,
      onShowShippingLabelsDialog: this.onShowShippingLabelsDialog,
      onStageChangeWithSummaryReplaced: this.onStageChangeWithSummaryReplaced,
      onStageChange: this.onStageChange,
      onReceived: this.onReceived,
      onShowAndContinueOrderStockAvailabilityCheckDialog: this.onShowAndContinueOrderStockAvailabilityCheckDialog,
      onStageChangeWithPostFixReturnSetting: this.onStageChangeWithPostFixReturnSetting,
      onProductionComplete: this.onProductionComplete,
      onShowHoldDialog: this.onShowHoldDialog,
      onShowOrderStockAvailabilityCheckDialog: this.onShowOrderStockAvailabilityCheckDialog,
      onShowRecordNoteDialog: this.onShowRecordNoteDialog,
      onShowEditEcdDialog: this.onShowEditEcdDialog,
    });
  }

  onShowOrderStockAvailabilityCheckDialog() {
    MessageService.sendMessage(messages.orderStockCheck);
  }

  geOrdersQueryFilters(filters: SchedulerFilterProps, mode: SchedulerMode) {
    const queryFilters: string[] = [];
    if (mode === 'scheduler') {
      if (filters.productLine === 'Curtains') {
        // the curtains product line was chosen -> filter unfinished curtains in current factory
        queryFilters.push(
          JSON.stringify({
            field: `factory_order.factory_chain->'${localStorage.getItem('current factory')}'->>'stage'`,
            value: ['Done', 'Cancelled', 'Between Factories', 'Post Fix Return'],
            condition: 'notIn',
          })
        );
      } else {
        // the other product line was chosen -> filter not unfinished orders in current factory
        queryFilters.push(
          JSON.stringify({
            field: 'factory_order.production_stage',
            value: ['Done', 'Cancelled', 'Post Fix Return'],
            condition: 'notIn',
          })
        );
      }
      if (filters.ecdFrom) {
        queryFilters.push(
          JSON.stringify({
            field: 'factory_order.ecd',
            value: filters.ecdFrom,
            condition: '>=',
          })
        );
      }
      if (filters.ecdTo) {
        queryFilters.push(
          JSON.stringify({
            field: 'factory_order.ecd',
            value: filters.ecdTo,
            condition: '<=',
          })
        );
      }
    } else if (mode === 'completed') {
      if (filters.productLine === 'Curtains') {
        // the curtains product line was chosen -> filter finished curtains in current factory
        queryFilters.push(
          JSON.stringify({
            field: `factory_order.factory_chain->'${localStorage.getItem('current factory')}'->>'stage'`,
            value: ['Done', 'Between Factories', 'Post Fix Return'],
            condition: 'in',
          })
        );
      } else {
        // the other product line was chosen -> filter finished orders in current factory
        queryFilters.push(
          JSON.stringify({
            field: 'factory_order.production_stage',
            value: ['Done', 'Post Fix Return'],
            condition: 'in',
          })
        );
      }
      if (filters.completedAt && filters.completedAtFrom && filters.completedAtTo) {
        queryFilters.push(
          JSON.stringify({
            field: 'factory_order.completed_at',
            value: filters.completedAtFrom,
            condition: '>=',
          })
        );
        queryFilters.push(
          JSON.stringify({
            field: 'factory_order.completed_at',
            value: filters.completedAtTo,
            condition: '<=',
          })
        );
      }
    }

    queryFilters.push(
      JSON.stringify({
        field: 'factory_order.product_line',
        value: filters.productLine,
      })
    );

    queryFilters.push(
      JSON.stringify({
        field: 'type',
        value: filters.types,
        condition: 'in',
      })
    );

    if (filters.stages?.length) {
      if (filters.productLine === 'Curtains') {
        // only the curtains have the factory chain
        queryFilters.push(
          JSON.stringify({
            field: `factory_order.factory_chain->'${localStorage.getItem('current factory')}'->>'stage'`,
            value: filters.stages,
            condition: 'in',
          })
        );
      } else {
        // chosen other product line or all product lines
        queryFilters.push(
          JSON.stringify({
            orConditions: [
              {
                field: 'factory_order.production_stage',
                value: filters.stages,
                condition: 'in',
              },
              {
                field: `factory_order.factory_chain->'${localStorage.getItem('current factory')}'->>'stage'`,
                value: filters.stages,
                condition: 'in',
              },
            ],
          })
        );
      }
    }

    return queryFilters;
  }

  async onProductionComplete() {
    const selectedOrders = this.state.selectedOrders;
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    if (selectedOrders && selectedOrders.length > 0) {
      this.setState({
        loading: true,
      });

      const betweenFactoriesOrders: Order[] = [];
      const doneOrders: Order[] = [];
      const postFixReturnOrders: Order[] = [];
      for (const order of selectedOrders) {
        if (order.factory_order?.factory_id !== currentFactoryId) {
          betweenFactoriesOrders.push(order);
        } else if (order.factory_order?.post_fix_return) {
          postFixReturnOrders.push(order);
        } else {
          doneOrders.push(order);
        }
      }

      if (betweenFactoriesOrders.length) {
        await this.handleStageChangeRequest(betweenFactoriesOrders, 'Between Factories');
      }
      if (doneOrders.length) {
        await this.handleStageChangeRequest(doneOrders, 'Done');
      }
      if (postFixReturnOrders.length) {
        await this.handleStageChangeRequest(postFixReturnOrders, 'Post Fix Return');
      }
    }
  }

  async onReceived() {
    const selectedOrders = this.state.selectedOrders;
    if (!selectedOrders.length) {
      return;
    }
    const doneOrders: Order[] = [];
    const postFixReturnOrders: Order[] = [];
    for (const order of selectedOrders) {
      if (order.factory_order?.post_fix_return) {
        postFixReturnOrders.push(order);
      } else {
        doneOrders.push(order);
      }
    }
    if (doneOrders.length) {
      await this.handleStageChangeRequest(doneOrders, undefined, 'Done');
    }
    if (postFixReturnOrders.length) {
      await this.handleStageChangeRequest(postFixReturnOrders, undefined, 'Post Fix Return');
    }
  }

  onTypingTimerStart() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(this.loadData, 2000);
  }

  onTypingTimerClear() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  onFilterChange(filter: Partial<SchedulerFilterProps>) {
    this.setState(
      state => ({filter: {...state.filter, ...filter}}),
      () => {
        if (!filter.ecdTo && !filter.ecdFrom) {
          this.onTypingTimerStart();
        }
      }
    );
  }

  async onCompletedOrdersLazyLoad() {
    const {mode} = this.props;
    const {filter, pagination, orders} = this.state;
    this.setState({lazyLoading: true});
    const {orders: loadedOrders} = await this.loadOrders(filter, pagination.offset!, mode, pagination.pageSize);

    this.setState({
      orders: [...orders, ...loadedOrders],
      pagination: {...pagination, offset: pagination.offset! + pagination.pageSize!},
      lazyLoading: false,
    });
  }

  async onSchedulerOrdersLazyLoad() {
    const {mode} = this.props;
    const {filter, pagination, orders} = this.state;
    this.setState({lazyLoading: true});
    const lastDay = filter.ecdTo && filter.ecdTo < pagination.lastEcdDate! ? filter.ecdTo : pagination.lastEcdDate!;
    const lazyLoadEcdFrom = pagination.lastLoadedEcdDate!.startOf('day').plus({days: 1});
    const lazyLoadEcdTo = pagination.lastLoadedEcdDate!.plus({days: 5});

    const newEcdTo = lastDay < lazyLoadEcdTo ? lastDay : lazyLoadEcdTo;
    const injectedFilter = {...filter, ecdFrom: lazyLoadEcdFrom, ecdTo: newEcdTo};
    const result = await this.loadOrders(injectedFilter, 0, mode);
    const loadedOrders = this.sortOrdersByType(result.orders, true);

    this.setState({
      orders: [...orders, ...loadedOrders],
      pagination: {...pagination, lastLoadedEcdDate: newEcdTo},
      lazyLoading: false,
    });
  }

  onOrderSelect(order: Order) {
    const {selectedOrders} = this.state;
    const existingItem = selectedOrders.find(o => o.id === order.id);
    if (!existingItem) {
      this.setState({selectedOrders: [...selectedOrders, order]});
    }
  }

  onOrdersSelectionChange(orders: Order[]) {
    this.setState({selectedOrders: orders});
  }

  async onPrintCutSheets(prePowderOnly: boolean) {
    const {selectedOrders} = this.state;
    const selectedOrderIds: string[] = [];
    let atLeastOneStandard = false;
    for (const order of selectedOrders) {
      if (order.id) {
        selectedOrderIds.push(order.id);
        if (order.type === 'Standard') {
          atLeastOneStandard = true;
        }
      }
    }

    const printableData = await this.documentsService?.getCuttingSheets(selectedOrderIds);
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    const printerIP = await this.loadProductionPrinterIp(currentFactoryId);

    this.setState(
      {
        printableData: printableData,
        productionLabelsPrinterIp: printerIP,
        showProductionLabelsDialog:
          atLeastOneStandard && localStorage.getItem('current factory') === '86c7576a-2d38-4092-b1c3-5ef101f415bd',
        prePowderOnly: prePowderOnly,
      },
      () => MessageService.sendMessage('print-cutting-sheets')
    );
  }

  onHideProductionLabelsDialog() {
    this.setState({showProductionLabelsDialog: false});
  }

  onShowPurchaseOrderDialog() {
    this.setState({showPurchaseOrderDialog: true});
  }

  onHidePurchaseOrderDialog() {
    this.setState({showPurchaseOrderDialog: false});
  }

  onShowShippingLabelsDialog() {
    this.setState({showShippingLabelsDialog: true});
  }

  onHideShippingLabelsDialog() {
    this.setState({showShippingLabelsDialog: false});
  }

  async onStageChange(newStage: ProductionStageM2cAuShades, newSecondaryFactoriesStage?: ProductionStageM2cAuShades) {
    this.setState({
      loading: true,
    });
    const orders = [...this.state.selectedOrders];
    this.handleStageChangeRequest(orders, newStage, newSecondaryFactoriesStage);
  }

  async onStageChangeWithSummaryReplaced(replaceSummary: string) {
    const orders = [...this.state.selectedOrders];
    this.replaceSummaryOfOrders(orders, replaceSummary).then(() => this.onStageChange('Ready'));
  }

  async onShowAndContinueOrderStockAvailabilityCheckDialog() {
    MessageService.sendMessage(messages.orderStockCheckAndContinue);
  }

  async replaceSummaryOfOrders(orders: Order[], replaceSummary: string) {
    const date = DateTime.fromJSDate(new Date()).toFormat(formats.date);
    const dateSummary = `&#10004; (${date})`;

    const updatedOrders = orders.map(o => {
      const oldSummary = o.summary ?? '';
      const newSummary = `${replaceSummary} ${dateSummary}`;
      o.summary = oldSummary.replace(replaceSummary, newSummary);
      return o;
    });

    await this.saveOrders(updatedOrders);
  }

  async handleStageChangeRequest(
    orders: Order[],
    newStage?: ProductionStageM2cAuShades,
    newSecondaryFactoriesStage?: ProductionStageM2cAuShades,
    stageChangeReason?: string
  ) {
    const promises = orders.map(order => {
      const factoryOrderPatch: FactoryOrderPatch = getNewProductionStagesPatch(
        order.factory_order!,
        newStage,
        newSecondaryFactoriesStage,
        stageChangeReason
      );
      return this.updateFactoryOrder(factoryOrderPatch, order).then(updatedFactoryOrder => {
        if (updatedFactoryOrder) {
          this.toastService?.showSuccess(this.toast, `Stage Updated for ${updatedFactoryOrder.id} successfully.`);
          this.factoryOrdersService?.doStockUpdate(
            order.factory_order!.production_stage,
            updatedFactoryOrder.production_stage,
            updatedFactoryOrder.id!
          );
        }
      });
    });

    Promise.all(promises)
      .then(() => {
        this.loadData();
      })
      .catch(error => {
        this.twoToast?.showError('Order update failed');
        this.setState({
          loading: false,
        });
        console.error(error);
      });
  }

  async updateFactoryOrder(factoryOrderPatch: FactoryOrderPatch, order: Order) {
    return this.factoryOrdersService?.updateFactoryOrder(order.id ?? '', factoryOrderPatch);
  }

  async saveOrders(orders: Order[]) {
    if (orders && orders.length > 0) {
      Promise.all(
        orders.map((order: Order) => {
          return this.ordersService
            ?.updateOrder(order.id ?? '', order as OrderPatch)
            .then(() => {
              this.twoToast?.showSuccess(`Order ${order.id} updated successfully.`);
            })
            .catch(error => {
              this.twoToast?.showError(`${order.id} update failed`);
              console.error('error: ' + error);
            });
        })
      );
    }
  }

  async onStageChangeWithPostFixReturnSetting() {
    const orders = [...this.state.selectedOrders];
    await this.setPostFixReturn(orders, true);
  }

  async setPostFixReturn(orders: Order[], postFixReturnValue: boolean) {
    const promises = [];

    for (const order of orders) {
      if (order.factory_order) {
        const factoryOrderPatch = {
          post_fix_return: postFixReturnValue,
        };
        promises.push(this.updateFactoryOrder(factoryOrderPatch, order));
      }
    }

    return Promise.all(promises).then(() => {
      this.onShowAndContinueOrderStockAvailabilityCheckDialog();
    });
  }

  onShowHoldDialog() {
    this.setState({showHoldDialog: true});
  }

  onHideHoldDialog() {
    this.setState({showHoldDialog: false});
  }

  async onHoldStageChange() {
    const {selectedOrders, holdReason} = this.state;
    this.setState({
      loading: true,
    });
    this.handleStageChangeRequest(selectedOrders, 'On Hold', undefined, holdReason);
  }

  onHoldReasonChange(reason: string) {
    this.setState({holdReason: reason});
  }

  onShowRecordNoteDialog() {
    this.setState({showRecordNoteDialog: true});
  }

  onHideRecordNoteDialog() {
    this.setState({showRecordNoteDialog: false});
  }

  onHideEditEcdDialog() {
    this.setState({showEditEcdDialog: false});
    this.loadData();
  }

  onShowEditEcdDialog() {
    this.setState({showEditEcdDialog: true});
  }

  async onUserCheck(userId: string) {
    if (!userId.length) {
      return;
    }
    const usersMap = this.state.usersMap;
    if (!usersMap.has(userId)) {
      const user = await this.loadUser(userId);
      if (user) {
        this.setState({usersMap: new Map(usersMap.set(userId, user))});
      }
    }
  }

  setCollapsedRows = (collapsedRows: string[]) => {
    this.setState(() => ({collapsedRows}));
  };

  injectLastNotes(createdTles: TimeLineEvent[]) {
    this.setState(state => {
      const orders = state.orders.map(order => {
        const tle = createdTles.find(tle => tle.entity_id === order.id);
        if (tle) {
          order.last_note = tle;
        }
        return order;
      });

      return {orders};
    });
  }

  render() {
    const {mode} = this.props;
    const {
      loading,
      factoryOrdersSizeSum,
      lazyLoading,
      orders,
      selectedOrders,
      filter,
      totalOrders,
      pagination,
      productionLabelsPrinterIp,
      prePowderOnly,
      printableData,
      showProductionLabelsDialog,
      showPurchaseOrderDialog,
      showHoldDialog,
      holdReason,
      showRecordNoteDialog,
      userLoading,
      usersMap,
      collapsedRows,
    } = this.state;
    return (
      <div className="orders-scheduler">
        <div className="p-mb-3">
          <SchedulerFilter
            loading={loading || lazyLoading}
            filter={filter}
            onFilterChange={this.onFilterChange}
            pauseTypingTimer={this.onTypingTimerClear}
            resumeTypingTimer={this.onTypingTimerStart}
            factoryOrdersSizeSum={factoryOrdersSizeSum}
            mode={mode}
          />
        </div>
        <SchedulerTable
          loading={loading}
          lazyLoading={lazyLoading}
          orders={orders}
          selectedOrders={selectedOrders}
          onOrdersLazyLoad={mode === 'completed' ? this.onCompletedOrdersLazyLoad : this.onSchedulerOrdersLazyLoad}
          onOrderSelect={this.onOrderSelect}
          pagination={pagination}
          totalOrders={totalOrders}
          filter={filter}
          initMenuItems={this.initMenuItems}
          onOrdersSelectionChange={this.onOrdersSelectionChange}
          onUserCheck={this.onUserCheck}
          userLoading={userLoading}
          usersMap={usersMap}
          mode={mode}
          collapsedRows={collapsedRows}
          setCollapsedRows={this.setCollapsedRows}
        />
        {!!selectedOrders.length && (
          <>
            <CuttingSheets
              type={selectedOrders[0].factory_order?.product_line ?? ''}
              data={printableData}
              prePowderOnly={prePowderOnly}
            />
            <ProductionLabelsDialog
              show={showProductionLabelsDialog}
              printerIp={productionLabelsPrinterIp}
              data={printableData}
              onHide={this.onHideProductionLabelsDialog}
            />
            <PurchaseOrderAddDialog
              toast={this.toast}
              showPurchaseOrderDialog={showPurchaseOrderDialog}
              closeDialog={this.onHidePurchaseOrderDialog}
              order={selectedOrders[0]}
            />
            <Dialog
              header={
                selectedOrders.length > 1
                  ? 'Shipping Labels for Multiple Orders'
                  : `Shipping Labels for ${selectedOrders[0].id}`
              }
              visible={this.state.showShippingLabelsDialog}
              style={{width: '80%'}}
              modal
              onHide={this.onHideShippingLabelsDialog}
              className="shipment-label-dialog"
            >
              <ShipmentComponent orders={selectedOrders} onHide={this.onHideShippingLabelsDialog} />
            </Dialog>
            <OrderStockDialog toast={this.toast} orders={selectedOrders} />
            <TwoDialog
              className="purchaser-order-date-dialog"
              headerTitle={'On Hold'}
              showDialog={showHoldDialog}
              width={60}
              onHide={this.onHideHoldDialog}
              onSave={this.onHoldStageChange}
              loading={loading}
            >
              <ToOnHoldDialog reason={holdReason} handleOnHoldReasonChange={this.onHoldReasonChange} />
            </TwoDialog>
            <OrderNoteDialog
              showDialog={showRecordNoteDialog}
              onHide={this.onHideRecordNoteDialog}
              selectedOrders={selectedOrders}
              injectLastNotes={this.injectLastNotes}
            />
            <EditEcdDialog
              showDialog={this.state.showEditEcdDialog}
              onHide={this.onHideEditEcdDialog}
              orders={selectedOrders}
            />
          </>
        )}

        <Toast ref={this.toast} />
      </div>
    );
  }
}
