import {Column} from 'primereact/column';
import React from 'react';
import {AppColumnMenuBodyTemplate, AppMenuItem, AppMenuItemTemplate, TwoDataTable} from 'two-app-ui';
import {PurchaseOrder} from 'two-core';
import {faGear, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {MenuItemOptions} from 'primereact/menuitem';
import {InputNumber} from 'primereact/inputnumber';
import {PurchaseOrderItem} from 'two-core/build/cjs/src/purchase-order';

interface Props {
  purchaseOrderItems: PurchaseOrderItem[];
  purchaseOrder?: PurchaseOrder;
  readonly?: boolean;
  onPoItemRemove?: (onPoItemRemove: PurchaseOrderItem) => void;
  onMakeDefault?: (poItem: PurchaseOrderItem) => void;
  onQuantityChange: (quantity: number, poItem: PurchaseOrderItem) => void;
}
export const PurchaseOrderSupplyItems = ({
  purchaseOrderItems,
  purchaseOrder,
  readonly,
  onPoItemRemove,
  onMakeDefault,
  onQuantityChange,
}: Props) => {
  const onQuantityBlur = (quantity: number, poItem: PurchaseOrderItem) => {
    const packageSize = poItem.supply_item?.package_size ?? 0.01;
    const roundedQty = Math.ceil(quantity / packageSize) * packageSize;

    onQuantityChange(roundedQty, poItem);
  };

  const initMenuItems = (poItem: PurchaseOrderItem): AppMenuItem[] => {
    const menuItems: AppMenuItem[] = [];

    if (!purchaseOrder || purchaseOrder.stage === 'Draft') {
      if (onMakeDefault && !poItem.supply_item?.default_option) {
        menuItems.push({
          label: 'Make Default',
          faIcon: faGear,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            onMakeDefault(poItem);
          },
        });
      }
      if (onPoItemRemove) {
        menuItems.push({
          label: 'Remove',
          faIcon: faTimes,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            onPoItemRemove(poItem);
          },
        });
      }
    }

    return menuItems;
  };

  const skuBody = (rowData: PurchaseOrderItem) => {
    const newClass = rowData.id! <= 0 ? 'new-supply-item' : '';
    const skuValue = `${rowData.supply_item?.sku}${rowData.supply_item?.default_option ? ' [D]' : ''}`;
    if (readonly) {
      return <span className={newClass}>{skuValue}</span>;
    }
    return (
      <AppColumnMenuBodyTemplate
        isDynamicMenuItems={true}
        initMenuItems={() => initMenuItems(rowData)}
        rowItemIdentifier={`${rowData.supply_item?.id}`}
        selectedItems={[]}
      >
        <span className={newClass}>{skuValue}</span>
      </AppColumnMenuBodyTemplate>
    );
  };

  const quantityBody = (rowData: PurchaseOrderItem) => {
    if (!readonly && purchaseOrder?.stage !== 'Delivered') {
      return (
        <InputNumber
          inputClassName="w-100"
          value={rowData.quantity}
          onChange={e => onQuantityChange(e.value ?? 0, rowData)}
          onBlur={e => onQuantityBlur(e.target.value ? +e.target.value : 0, rowData)}
          min={0}
          step={rowData.supply_item?.package_size ?? 0.01}
          maxFractionDigits={2}
          minFractionDigits={0}
          mode="decimal"
        />
      );
    }
    return rowData.quantity;
  };

  return (
    <div style={{height: '100%', width: '100%'}}>
      <TwoDataTable
        heightToScroll={'max'}
        selectedItems={[]}
        value={purchaseOrderItems}
        showPaging={false}
        activeFilters={[]}
        hideFilter={true}
      >
        <Column header="SKU" body={skuBody} className="col-min-xl" />
        <Column header="Unit" field="supply_item.reorder_unit" className="col-max-m" />
        <Column header="Package Size" field="supply_item.package_size_label" />
        <Column header="Unit Price" field="supply_item.unit_price" className="col-max-m" />
        <Column header="Reorder Min" field="supply_item.order_minimum" className="col-max-m" />
        <Column header="Qty" body={quantityBody} className="col-max-s" />
        <Column header="In UOM" field="qty_in_uom" className="col-max-s" />
      </TwoDataTable>
    </div>
  );
};
