import React from 'react';
import OrdersService from '../../services/OrdersService';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  TwoMessage,
  TwoAction,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  ToastService,
  TwoEntityComponent,
  TwoDialog,
} from 'two-app-ui';
import './Order.scss';
import OrderDetail from './OrderDetail';
import {
  CutSheetReport,
  FactoryOrderPatch,
  Order,
  FactoryAlarm,
  OrderPatch,
  ProductionStageM2cAuShades,
  QueryParameter,
  TimeLineEvent,
  Factory,
  TleContentStageTransition,
} from 'two-core';
import TleService from '../../services/TleService';
import {Subscription} from 'rxjs';
import {MessageService} from 'two-app-ui';
import {messages} from '../../config/messages';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCalendarAlt, faPencil, faBarcodeRead} from '@fortawesome/pro-regular-svg-icons';
import {Toast} from 'primereact/toast';
import AlarmDialog from '../Alarms/AlarmDialog';
import ShipmentComponent from '../Shipment/ShipmentComponent';
import CuttingSheets from '../CuttingSheets/CuttingSheets';
import {ProgressSpinner} from 'primereact/progressspinner';
import OrderEditComponent from './OrderEditComponent';
import OrderNoteDialog from './OrderNoteDialog';
import {faChevronDown, faExternalLink} from '@fortawesome/pro-light-svg-icons';
import {
  faPrint,
  faArrowCircleRight,
  faStickyNote,
  faBars,
  faFileInvoiceDollar,
  faRedo,
} from '@fortawesome/pro-regular-svg-icons';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import {CalendarChangeParams} from 'primereact/calendar';
import {confirmDialog} from 'primereact/confirmdialog';
import {DropdownChangeParams} from 'primereact/dropdown';
import AlarmsService from '../../services/AlarmsService';
import DocumentsService from '../../services/DocumentsService';
import FactoryOrderService from '../../services/FactoryOrdersService';
import FactoryOrdersService from '../../services/FactoryOrdersService';
import ShipmentsService from '../../services/ShipmentsService';
import FactoriesService from '../../services/FactoriesService';
import BomService from '../../services/BomService';
import OrderBoms from './OrderBoms';
import OrderStockDialog from '../OrderStock/OrderStockDialog';
import {Dialog} from 'primereact/dialog';
import ShipmentItemListComponent from '../Shipment/ShipmentItemListComponent';
import OosService from '../../services/OosService';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import ProductionLabelsDialog from '../ProductionLabels/ProductionLabelsDialog';
import {getFactoryOrderButtonMenuItems, getNewProductionStagesPatch} from '../../utils/FactoryOrderUtil';
import ToOnHoldDialog from '../Orders/ToOnHoldDialog';
import {getCurrentUserId} from '../../utils/UserUtil';
import PurchaseOrderAddDialog from '../PurchaseOrders/PurchaseOrderAddDialog';
import {EditEcdDialog} from './EditEcdDialog';

library.add(
  faCalendarAlt,
  faPencil,
  faChevronDown,
  faExternalLink,
  faPrint,
  faArrowCircleRight,
  faStickyNote,
  faBars,
  faFileInvoiceDollar,
  faRedo
);

interface RouteProps {
  id: string;
}

interface State {
  loadingOrder: boolean;
  loadingSecondaryView: boolean;
  order: Order;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
  showEditDialog: boolean;
  orderFactoryPrevStage: ProductionStageM2cAuShades;
  latestOrderSaved: Order;
  showNoteDialog: boolean;
  printableData: CutSheetReport[] | undefined;
  productionLabelPrinterIp?: string;
  showShipmentDialog: boolean;
  showEditAlarmDialog: boolean;
  showProductionLabelDialog: boolean;
  alarms: FactoryAlarm[];
  editAlarm: FactoryAlarm | undefined;
  alarmReferencedData: string;
  updatedOrder: OrderPatch;
  updatedFactoryOrder: FactoryOrderPatch;
  factory: Factory | undefined;
  showHoldDialog: boolean;
  saving: boolean;
  holdReason?: string;
  prePowderOnly: boolean;
  showPurchaseOrderDialog: boolean;
  showEditEcdDialog: boolean;
}

class OrderComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  ordersService: OrdersService | null = null;
  tleService: TleService | null = null;
  factoryOrdersService: FactoryOrdersService | null = null;
  alarmsService: AlarmsService | null = null;
  documentsService: DocumentsService | null = null;
  shipmentsService: ShipmentsService | null = null;
  factoryOrderService: FactoryOrderService | null = null;
  toastService: ToastService | null = null;
  factoriesService: FactoriesService | null = null;
  bomService: BomService | null = null;
  oosService: OosService | null = null;
  purchaseOrdersService: PurchaseOrdersService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);
    this.ordersService = null;
    this.tleService = null;

    this.state = {
      loadingOrder: false,
      loadingSecondaryView: false,
      order: new Order({}),
      events: [],
      items: [],
      showEditDialog: false,
      orderFactoryPrevStage: 'Init',
      latestOrderSaved: new Order({}),
      showNoteDialog: false,
      printableData: undefined,
      alarmReferencedData: '',
      showShipmentDialog: false,
      showEditAlarmDialog: false,
      showProductionLabelDialog: false,
      alarms: [],
      editAlarm: undefined,
      updatedOrder: {},
      updatedFactoryOrder: {},
      factory: undefined,
      showHoldDialog: false,
      saving: false,
      prePowderOnly: false,
      showPurchaseOrderDialog: false,
      showEditEcdDialog: false,
    };

    this.toast = React.createRef();

    this.loadEvents = this.loadEvents.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleFactoryOrderChange = this.handleFactoryOrderChange.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.closeShipmentDialog = this.closeShipmentDialog.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.showAlarm = this.showAlarm.bind(this);
    this.hideProductionLabels = this.hideProductionLabels.bind(this);
    this.onShowRecordNoteDialog = this.onShowRecordNoteDialog.bind(this);
    this.onShowConfirmReImportOrderDialog = this.onShowConfirmReImportOrderDialog.bind(this);
    this.onStageChangeWithSummaryReplaced = this.onStageChangeWithSummaryReplaced.bind(this);
    this.onStageChange = this.onStageChange.bind(this);
    this.onShowEditDialog = this.onShowEditDialog.bind(this);
    this.onPrintCutSheets = this.onPrintCutSheets.bind(this);
    this.onShowShippingLabelsDialog = this.onShowShippingLabelsDialog.bind(this);
    this.onReceived = this.onReceived.bind(this);
    this.onShowAndContinueOrderStockAvailabilityCheckDialog =
      this.onShowAndContinueOrderStockAvailabilityCheckDialog.bind(this);
    this.onStageChangeWithPostFixReturnSetting = this.onStageChangeWithPostFixReturnSetting.bind(this);
    this.onProductionComplete = this.onProductionComplete.bind(this);
    this.onShowOrderStockAvailabilityCheckDialog = this.onShowOrderStockAvailabilityCheckDialog.bind(this);
    this.onShowPurchaseOrderDialog = this.onShowPurchaseOrderDialog.bind(this);
    this.onShowHoldDialog = this.onShowHoldDialog.bind(this);
    this.handleOnHoldReasonChange = this.handleOnHoldReasonChange.bind(this);
    this.onHoldStageChange = this.onHoldStageChange.bind(this);
    this.closeHoldDialog = this.closeHoldDialog.bind(this);
    this.onShowEditEcdDialog = this.onShowEditEcdDialog.bind(this);
    this.onHideEditEcdDialog = this.onHideEditEcdDialog.bind(this);
  }

  componentDidMount() {
    this.factoriesService = this.context.factoriesService;
    this.ordersService = this.context.ordersService;
    this.tleService = this.context.tleService;
    this.ordersService = this.context.ordersService;
    this.factoryOrderService = this.context.factoryOrderService;
    this.documentsService = this.context.documentsService;
    this.shipmentsService = this.context.shipmentsService;
    this.alarmsService = this.context.alarmsService;
    this.factoryOrdersService = this.context.factoryOrdersService;
    this.toastService = this.context.toastService;
    this.bomService = this.context.bomService;
    this.oosService = this.context.oosService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.alarmUpdated) {
        this.loadAlarms(this.state.order.id ?? '');
      } else if (message === messages.orderNoteCreated) {
        this.loadEvents(id);
      } else if (message === messages.purchaseOrderUpdated) {
        this.loadOrder(id);
      } else if (message === messages.ordersUpdated) {
        this.loadEvents(id);
      } else if (message === messages.orderStageChangeReady) {
        //this.stageChange('Ready');
        this.checkStageChangeReady();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          this.props.history.push('/orders');
        }
      }
    });

    const id = this.props.match.params.id;
    this.loadOrder(id);
    this.loadEvents(id);
    this.loadAlarms(id);
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadOrder(orderId: string) {
    this.setState({loadingOrder: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: orderId,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.ordersService?.getOrders(params).then(data => {
      const order = (data.records as Order[])[0];
      this.setState({
        order: order,
        loadingOrder: false,
        latestOrderSaved: order,
      });
    });
  }

  async checkStageChangeReady() {
    this.setState({
      loadingOrder: true,
    });

    return this.onStageChange('Ready').then(() => {
      this.setState({
        loadingOrder: false,
      });
      this.loadOrder(this.props.match.params.id);
    });
  }

  reloadData() {
    const id = this.state.order.id ?? '';
    this.loadOrder(id);
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'order',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tleService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order events load failed, please try again.');
        this.setState({loadingSecondaryView: false});
      });
  }
  async stageChange(newStage: ProductionStageM2cAuShades) {
    this.setState({
      loadingOrder: true,
    });
    this.onStageChange(newStage);
  }

  onCancel() {
    const order = this.state.order;
    const factoryOrderPatch: FactoryOrderPatch = getNewProductionStagesPatch(
      order.factory_order!,
      'Cancelled',
      'Cancelled'
    );

    this.factoryOrdersService
      ?.updateFactoryOrder(order.id ?? '', factoryOrderPatch)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Order updated successfully.');
        this.reloadData();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Order update failed, please try again.');
        console.error(error);
      });
  }

  onShowConfirmReImportOrderDialog() {
    confirmDialog({
      message:
        'Are you sure you want to re-import this order? BOM, Shipping Labels and Cut Sheets will be generated anew.',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        if (this.state.order.id) {
          this.ordersService
            ?.reImportOrder(this.state.order.id)
            .then(() => {
              this.toastService?.showSuccess(
                this.toast,
                'Order re import has been initiated, please allow up to 5 minutes to complete'
              );
            })
            .catch(() => {
              this.toastService?.showError(this.toast, 'Order re import failed, contact support');
            });
        }
      },
    });
  }

  async setOrder(order: Order) {
    this.setState({loadingOrder: true});

    this.setState({
      order: order,
      latestOrderSaved: order,
      updatedFactoryOrder: {
        ecd: order.factory_order?.ecd,
        production_stage: order.factory_order?.production_stage,
      },
      updatedOrder: {
        type: order.type,
        priority: order.priority,
      },
      loadingOrder: false,
    });
  }

  async loadAlarms(orderId: string) {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'reference_id',
        value: orderId,
        condition: 'like',
      })
    );

    filters.push(
      JSON.stringify({
        field: 'referenced_entity',
        value: 'Order',
        condition: '=',
      })
    );

    const sortBy = JSON.stringify({
      field: 'created_at',
      direction: 'ASC',
    });

    const params: QueryParameter = {
      filters: filters,
      orderBys: [sortBy],
    };

    this.alarmsService
      ?.getAlarms(params)
      .then(data => {
        const alarms = data.records as FactoryAlarm[];

        this.setState({
          alarms: alarms,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, alarm records load failed, please try again.');
      });
  }

  handleOrderChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | CalendarChangeParams) {
    const order = this.state.updatedOrder;
    const updateOrder = {...order, [e.target.name]: e.target.value};
    this.setState({updatedOrder: updateOrder});
  }

  handleFactoryOrderChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | CalendarChangeParams) {
    const orderPrevStage = (this.state.order.factory_order?.production_stage as ProductionStageM2cAuShades) ?? 'Init';
    if (e.target.name === 'ecd') {
      e.target.value = new Date(
        Date.UTC(e.target.value.getFullYear(), e.target.value.getMonth(), e.target.value.getDate())
      );
    }

    const factoryOrder = this.state.updatedFactoryOrder;
    const updatedFactoryOrder = {
      ...factoryOrder,
      [e.target.name]: e.target.value,
    };
    this.setState({
      updatedFactoryOrder: updatedFactoryOrder,
      orderFactoryPrevStage: orderPrevStage,
    });
  }

  cancelEdit() {
    this.setState({order: this.state.latestOrderSaved, showEditDialog: false});
  }

  onShowRecordNoteDialog() {
    this.setState({showNoteDialog: true});
  }

  closeHoldDialog() {
    this.setState({showHoldDialog: false});
  }

  async onPrintCutSheets(prePowderOnly: boolean) {
    if (!this.state.order.id) {
      throw new Error('Invalid order');
    }

    const selectedOrders: string[] = [this.state.order.id];
    const printableData = await this.documentsService?.getCuttingSheets(selectedOrders);
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    const printerIP = await this.loadProductionPrinterIp(currentFactoryId);

    this.setState(
      {
        printableData: printableData,
        productionLabelPrinterIp: printerIP,
        showProductionLabelDialog:
          this.state.order.type === 'Standard' &&
          localStorage.getItem('current factory') === '86c7576a-2d38-4092-b1c3-5ef101f415bd',
        prePowderOnly: prePowderOnly,
      },
      () => MessageService.sendMessage('print-cutting-sheets')
    );
  }

  async loadProductionPrinterIp(currentFactoryId: string) {
    return this.factoriesService
      ?.getFactory(currentFactoryId)
      .then(data => {
        return data.settings?.printers?.production_label_printer_ip ?? '';
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Production Label Printer IP not found');
        return undefined;
      });
  }

  closeShipmentDialog() {
    this.setState({showShipmentDialog: false});
  }

  hideProductionLabels() {
    this.setState({showProductionLabelDialog: false});
  }

  async saveOrder() {
    if (!this.state.order.id) {
      throw new Error('Invalid order');
    }

    const order = this.state.order;
    const latestOrderSaved = this.state.latestOrderSaved;

    order.items = [];

    const updatedOrder = this.state.updatedOrder;
    const updatedFactoryOrder = this.state.updatedFactoryOrder;

    if (
      (updatedOrder.type && latestOrderSaved.type !== updatedOrder.type) ||
      (updatedOrder.priority && latestOrderSaved.priority !== updatedOrder.priority) ||
      (updatedFactoryOrder.ecd && latestOrderSaved.factory_order?.ecd !== updatedFactoryOrder.ecd) ||
      (updatedFactoryOrder.production_stage &&
        latestOrderSaved.factory_order?.production_stage !== updatedFactoryOrder.production_stage) ||
      (updatedFactoryOrder.summary && latestOrderSaved.factory_order?.summary !== updatedFactoryOrder.summary)
    ) {
      this.setState({loadingOrder: true});

      let isOrderUpdated = false;
      if (Object.keys(updatedFactoryOrder).length) {
        await this.factoryOrdersService
          ?.updateFactoryOrder(this.state.order.id ?? '', updatedFactoryOrder)
          .then(order => {
            isOrderUpdated = true;

            if (Object.prototype.hasOwnProperty.call(updatedFactoryOrder, 'production_stage')) {
              const previousProductionOrderStage = this.state.orderFactoryPrevStage;
              this.factoryOrderService?.doStockUpdate(
                previousProductionOrderStage,
                updatedFactoryOrder.production_stage as ProductionStageM2cAuShades,
                order.id as string
              );
            }

            this.setState({updatedFactoryOrder: {}});
          })
          .catch(() => {
            Promise.reject('Factory order ' + this.state.order.id + ' not updated.');
            this.toast.current?.show({
              contentClassName: '',
              severity: 'error',
              summary: 'Error',
              detail: `Sorry, ${this.state.order.id} update failed, please try again.`,
              sticky: true,
            });
          });
      }

      if (Object.keys(updatedOrder).length) {
        await this.ordersService
          ?.updateOrder(this.state.order.id, updatedOrder)
          .then(() => {
            isOrderUpdated = true;
            this.setState({updatedOrder: {}});
          })
          .catch(error => {
            console.error(error);
            this.toast.current?.show({
              contentClassName: '',
              severity: 'error',
              summary: 'Error',
              detail: `Sorry, ${this.state.order.id} update failed, please try again.`,
              sticky: true,
            });
            this.setState({loadingOrder: false});
          });
      }

      if (isOrderUpdated) {
        this.toast.current?.show({
          contentClassName: '',
          severity: 'success',
          summary: 'Success',
          detail: `Order ${this.state.order.id} updated successfully.`,
          life: 2000,
        });
        this.loadEvents(this.state.order.id ?? '');
      }
      this.setState({
        loadingOrder: false,
      });
      this.reloadData();
    }

    this.setState({
      showEditDialog: false,
    });
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    return currentUser?.uuid ?? '';
  }

  async showAlarm(alarm: FactoryAlarm) {
    const referenceData = `${this.state.order.id} ${this.state.order.reference}`;
    await this.setState({
      editAlarm: alarm,
      showEditAlarmDialog: true,
      alarmReferencedData: referenceData,
    });

    MessageService.sendMessage(messages.alarmEdit);
  }

  getActions(): TwoAction[] {
    const {order} = this.state;
    return getFactoryOrderButtonMenuItems(order, {
      onShowEditDialog: this.onShowEditDialog,
      onPrintCutSheets: this.onPrintCutSheets,
      onShowShippingLabelsDialog: this.onShowShippingLabelsDialog,
      onShowRecordNoteDialog: this.onShowRecordNoteDialog,
      onShowConfirmReImportOrderDialog: this.onShowConfirmReImportOrderDialog,
      onCancel: this.onCancel,
      onStageChange: this.onStageChange,
      onStageChangeWithSummaryReplaced: this.onStageChangeWithSummaryReplaced,
      onReceived: this.onReceived,
      onShowAndContinueOrderStockAvailabilityCheckDialog: this.onShowAndContinueOrderStockAvailabilityCheckDialog,
      onStageChangeWithPostFixReturnSetting: this.onStageChangeWithPostFixReturnSetting,
      onProductionComplete: this.onProductionComplete,
      onShowHoldDialog: this.onShowHoldDialog,
      onShowOrderStockAvailabilityCheckDialog: this.onShowOrderStockAvailabilityCheckDialog,
      onShowPurchaseOrderDialog: this.onShowPurchaseOrderDialog,
      onShowEditEcdDialog: this.onShowEditEcdDialog,
    });
  }

  onHideEditEcdDialog() {
    this.setState({showEditEcdDialog: false});
    this.reloadData();
  }

  onShowEditEcdDialog() {
    this.setState({showEditEcdDialog: true});
  }

  onShowOrderStockAvailabilityCheckDialog() {
    MessageService.sendMessage(messages.orderStockCheck);
  }

  onShowPurchaseOrderDialog() {
    this.setState({showPurchaseOrderDialog: true});
  }

  onShowHoldDialog() {
    this.setState({showHoldDialog: true});
  }

  onStageChangeWithPostFixReturnSetting() {
    this.setPostFixReturn(true);
  }

  onShowShippingLabelsDialog() {
    this.setState({showShipmentDialog: true});
  }
  onShowEditDialog() {
    this.setState({showEditDialog: true});
  }

  async onShowAndContinueOrderStockAvailabilityCheckDialog() {
    MessageService.sendMessage(messages.orderStockCheckAndContinue);
  }

  async setPostFixReturn(postFixReturnValue: boolean) {
    const order = this.state.order;
    if (order.factory_order) {
      order.factory_order.post_fix_return = postFixReturnValue;
      this.setState({updatedOrder: order as OrderPatch});
      this.saveOrder().then(() => {
        this.checkStageChangeReady();
        //this.showAndContinueOrderStockAvailabilityCheck();
      });
    }
  }

  async onProductionComplete() {
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    const order = this.state.order;
    if (order.factory_order?.factory_id !== currentFactoryId) {
      await this.onStageChange('Between Factories');
    } else if (order.factory_order?.post_fix_return) {
      await this.onStageChange('Post Fix Return');
    } else {
      await this.onStageChange('Done');
    }
  }

  async onReceived() {
    const order = this.state.order;
    if (order.factory_order?.post_fix_return) {
      this.onStageChange(undefined, 'Post Fix Return');
    } else {
      this.onStageChange(undefined, 'Done');
    }
  }

  async onHoldStageChange() {
    const {holdReason} = this.state;
    this.setState({showHoldDialog: false});
    this.onStageChange('On Hold', undefined, holdReason);
  }

  handleOnHoldReasonChange(reason: string) {
    this.setState({holdReason: reason});
  }

  async onStageChangeWithSummaryReplaced(replaceSummary: string) {
    this.replaceSummaryOfOrders(this.state.order, replaceSummary).then(() => this.onStageChange('Ready'));
  }

  async replaceSummaryOfOrders(order: Order, replaceSummary: string) {
    const date = DateTime.fromJSDate(new Date()).toFormat(formats.date);
    const dateSummary = `&#10004; (${date})`;
    const oldSummary = order.summary ?? '';
    const newSummary = `${replaceSummary} ${dateSummary}`;
    order.summary = oldSummary.replace(replaceSummary, newSummary);
    this.setState({updatedOrder: order as OrderPatch});
    this.saveOrder();
  }

  async onStageChange(
    newStage?: ProductionStageM2cAuShades,
    newSecondaryFactoriesStage?: ProductionStageM2cAuShades,
    stageChangeReason?: string
  ) {
    const {order} = this.state;
    const factoryOrderPatch: FactoryOrderPatch = getNewProductionStagesPatch(
      order.factory_order!,
      newStage,
      newSecondaryFactoriesStage,
      stageChangeReason
    );
    this.updateFactoryOrder(factoryOrderPatch, order)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Stage updated successfully.');
        this.factoryOrderService
          ?.doStockUpdate(order.factory_order!.production_stage, factoryOrderPatch.production_stage!, order.id!)
          .finally(() => this.reloadData());
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Order Stage update failed, please try again.');
        console.error(error);
      });
  }

  async updateFactoryOrder(factoryOrderPatch: FactoryOrderPatch, order: Order) {
    return this.factoryOrdersService?.updateFactoryOrder(order.id ?? '', factoryOrderPatch).then(() => {
      this.loadEvents(this.state.order.id ?? '');
    });
  }

  render() {
    const {order, items, prePowderOnly, showPurchaseOrderDialog} = this.state;

    const dialogBody = (
      <OrderEditComponent
        order={this.state.order}
        handleOrderChange={this.handleOrderChange}
        handleFactoryOrderChange={this.handleFactoryOrderChange}
        updatedFactoryOrder={this.state.updatedFactoryOrder}
        updatedOrder={this.state.updatedOrder}
      />
    );

    return order.id ? (
      <>
        <TwoEntityComponent title={order.id} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingOrder ? (
              <OrderDetail
                order={order}
                alarms={this.state.alarms}
                showAlarm={this.showAlarm}
                history={this.props.history}
              />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? <TwoTimeline key={order.id} items={items} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Bom" icon={faBars} tooltip="Bom">
            {!this.state.loadingSecondaryView ? <OrderBoms order={order} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Labels" icon={faBarcodeRead} tooltip="Labels">
            {!this.state.loadingSecondaryView ? (
              <ShipmentItemListComponent orders={[order]} hideId={true} selectable={false} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
        </TwoEntityComponent>

        <Toast ref={this.toast} />

        <OrderNoteDialog
          showDialog={this.state.showNoteDialog}
          onHide={() => this.setState({showNoteDialog: false})}
          selectedOrders={[this.state.order]}
        />
        <TwoDialog
          headerTitle={'Edit Order'}
          showDialog={this.state.showEditDialog}
          visible={this.state.showEditDialog}
          width={70}
          closable={false}
          onHide={this.cancelEdit}
          onSave={this.saveOrder}
          loading={this.state.loadingOrder}
        >
          {dialogBody}
        </TwoDialog>
        <OrderStockDialog toast={this.toast} orders={[this.state.order]} />
        <CuttingSheets
          type={this.state.order.factory_order?.product_line}
          data={this.state.printableData}
          prePowderOnly={prePowderOnly}
        />
        <ProductionLabelsDialog
          show={this.state.showProductionLabelDialog}
          printerIp={this.state.productionLabelPrinterIp}
          data={this.state.printableData}
          onHide={this.hideProductionLabels}
        />
        <Dialog
          header={`Shipping Labels for ${this.state.order.id ?? ''}`}
          visible={this.state.showShipmentDialog}
          style={{width: '80%'}}
          modal
          onHide={() => this.closeShipmentDialog()}
          className="shipment-label-dialog"
        >
          <ShipmentComponent orders={[this.state.order]} onHide={this.closeShipmentDialog} />
        </Dialog>
        <TwoDialog
          className="purchaser-order-date-dialog"
          headerTitle={'On Hold'}
          showDialog={this.state.showHoldDialog}
          width={60}
          onHide={this.closeHoldDialog}
          onSave={this.onHoldStageChange}
          loading={this.state.saving}
        >
          <ToOnHoldDialog
            reason={this.state.holdReason ?? ''}
            handleOnHoldReasonChange={this.handleOnHoldReasonChange}
          />
        </TwoDialog>
        <AlarmDialog
          showDialog={this.state.showEditAlarmDialog}
          toast={this.toast}
          selectedAlarm={this.state.editAlarm}
          selectedAlarmReferencedData={this.state.alarmReferencedData}
          onHide={() => {
            this.setState({
              showEditAlarmDialog: false,
              editAlarm: undefined,
              alarmReferencedData: '',
            });
          }}
        />
        <PurchaseOrderAddDialog
          toast={this.toast}
          showPurchaseOrderDialog={showPurchaseOrderDialog}
          closeDialog={() => this.setState({showPurchaseOrderDialog: false})}
          order={order}
        />
        <EditEcdDialog showDialog={this.state.showEditEcdDialog} onHide={this.onHideEditEcdDialog} orders={[order]} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(OrderComponent);
