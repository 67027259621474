import React from 'react';
import {TwoDialog} from 'two-app-ui';
import {InvalidInventoryItem} from './PurchaseOrderItems';

interface Props {
  invalidItems: InvalidInventoryItem[];
  showDialog: boolean;
  onHide: () => void;
  onSave: () => void;
}

export const PurchaseOrderEditConfirmationDialog = ({showDialog, onHide, onSave, invalidItems}: Props) => {
  return (
    <>
      <TwoDialog
        header="Not All Requirements Met"
        onHide={onHide}
        onSave={onSave}
        saveButtonTitle="Save anyway"
        showDialog={showDialog}
        style={{width: '50vw'}}
        breakpoints={{'768px': '80vw', '576px': '90vw'}}
        draggable={false}
      >
        <div className="p-d-flex p-flex-column p-mx-3 w-100">
          <div className="p-mb-3">The following items may not be completely covered by the purchase order:</div>
          <div className="p-mx-3">
            <ul>
              {invalidItems.map(invalidItem => (
                <li key={invalidItem.inventoryItem.id} className="p-mb-2">
                  {`${invalidItem.inventoryItem.name}   => ${invalidItem.reason}.`}
                </li>
              ))}
            </ul>
          </div>
          <div className="p-mt-3 p-mb-1 p-mx-3">Are you sure you want to continue?</div>
        </div>
      </TwoDialog>
    </>
  );
};
