import React from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {DateTime} from 'luxon';
import {priorities, stages} from './Constants/constants';
import {FactoryOrderPatch, Order, OrderPatch} from 'two-core';
import formats from '../../config/formats';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {InputText} from 'primereact/inputtext';

interface OrderEditProps {
  order: Order;
  updatedOrder: OrderPatch;
  updatedFactoryOrder: FactoryOrderPatch;
  handleOrderChange: (e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | CalendarChangeParams) => void;
  handleFactoryOrderChange: (
    e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | CalendarChangeParams
  ) => void;
}

const OrderEditComponent = ({
  order,
  updatedFactoryOrder,
  handleOrderChange,
  handleFactoryOrderChange,
  updatedOrder,
}: OrderEditProps) => {
  const editingOrder = {...order, ...updatedOrder};
  return (
    <>
      <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
        <label htmlFor="code" className="p-col-1">
          order code
        </label>
        <div className="p-col-3 p-p-0">
          <span>{order.id ?? ''} </span>
        </div>

        <label htmlFor="reference" className="p-col-1">
          reference
        </label>
        <div className="p-col-3 p-p-0">
          <span>{order.reference ?? ''} </span>
        </div>

        <label htmlFor="priority" className="p-col-1">
          priority
        </label>
        <div className="p-col-12 p-md-9">
          <Dropdown
            optionLabel="label"
            optionValue="value"
            name="priority"
            value={editingOrder.priority}
            options={priorities}
            onChange={e => handleOrderChange(e)}
            placeholder="Select a Priority"
          />
        </div>
      </div>

      <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
        <label htmlFor="product_line" className="p-col-1">
          product line
        </label>
        <div className="p-col-3 p-p-0">
          <span>{order.factory_order?.product_line ?? ''} </span>
        </div>

        <label htmlFor="type" className="p-col-1">
          type
        </label>
        <div className="p-col-3 p-p-0">
          <span>{order.type ?? ''} </span>
        </div>

        <label htmlFor="stage" className="p-col-1">
          stage
        </label>
        <div className="p-col-3 p-p-0">
          <span>{order.factory_order?.production_stage ?? ''} </span>
          {/*<Dropdown*/}
          {/*  className="w-100"*/}
          {/*  optionLabel="label"*/}
          {/*  optionValue="value"*/}
          {/*  name="production_stage"*/}
          {/*  value={updatedFactoryOrder.production_stage ?? editingOrder.factory_order?.production_stage}*/}
          {/*  options={stages}*/}
          {/*  onChange={e => handleFactoryOrderChange(e)}*/}
          {/*  placeholder="Select a production stage"*/}
          {/*/>*/}
        </div>
      </div>

      <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
        <label htmlFor="ecd" className="p-col-1">
          ecd
        </label>
        <div className="p-col-3 p-p-0">
          <span className="p-fluid">
            <Calendar
              className="w-100"
              name="ecd"
              value={
                updatedFactoryOrder.ecd ??
                DateTime.fromISO(
                  typeof order.factory_order?.ecd === 'string'
                    ? (order.factory_order?.ecd as string)
                    : (order.factory_order?.ecd?.toISOString() as string),
                  {zone: 'utc'}
                ).toJSDate()
              }
              dateFormat={formats.calendarInputDate}
              onChange={e => handleFactoryOrderChange(e)}
            />
          </span>
        </div>

        <label htmlFor="size" className="p-col-1">
          size
        </label>
        <div className="p-col-3 p-p-0">
          <span>{order.size ?? ''} </span>
        </div>

        <label htmlFor="summary" className="p-col-1">
          summary
        </label>
        <div className="p-col-3 p-p-0">
          <InputText
            className="w-100"
            value={updatedFactoryOrder?.summary ?? order.factory_order?.summary ?? ''}
            name="summary"
            onChange={e => {
              handleFactoryOrderChange(e);
            }}
          />
        </div>
      </div>

      <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
        <label htmlFor="approved" className="p-col-1">
          approved
        </label>
        <div className="p-col-3 p-p-0">
          <span>{order.approved_at ? DateTime.fromISO(order.approved_at.toString()).toFormat(formats.date) : ''}</span>
        </div>

        <label htmlFor="scheduled" className="p-col-1">
          sent to floor
        </label>
        <div className="p-col-3 p-p-0">
          <span>
            {order.factory_order?.scheduled_for
              ? DateTime.fromISO(order.factory_order?.scheduled_for.toString()).toFormat(formats.date)
              : ''}
          </span>
        </div>

        <label htmlFor="completed" className="p-col-1">
          completed
        </label>
        <div className="p-col-3 p-p-0">
          <span>
            {order.factory_order?.completed_at
              ? DateTime.fromISO(order.factory_order?.completed_at.toString()).toFormat(formats.date)
              : ''}
          </span>
        </div>
      </div>

      <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0 p-pb-0">
        <label htmlFor="QC" className="p-col-1">
          QC
        </label>
        <div className="p-col-3 p-p-0">
          <span>{order.factory_order?.qc_by} </span>
        </div>
      </div>
    </>
  );
};

export default OrderEditComponent;
